import { useContext, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import routes from "routes";
import { useMutation } from "@apollo/client";
import { login } from "services/login";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/background.jpg";
import InstallPWA from "components/InstallButton";
import { MessageManager } from "context";
import ModalCambiarPass from "../ModalCambiarPass";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import ModalRecuperarPass from "../components/ModalRecuperarPass";
import ModalNuevaPass from "../components/ModalNuevaPass";

function SignIn() {
  const sha512 = require("js-sha512");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorLogin, setErrorLogin] = useState(null);
  const [openCambiarPass, setOpenCambiarPass] = useState(false);
  const [openRecuperarPass, setOpenRecuperarPass] = useState(false);
  const [openNuevaPass, setOpenNuevaPass] = useState(false);
  const [recoveryData, setRecoveryData] = useState(null);

  const [loginUser, { data, loading, error }] = useMutation(login);

  const handleChanges = (event) => {
    const { name, value } = event.target;
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    setErrorLogin(null);
    if (username !== "" && password !== "") {
      let history = JSON.parse(localStorage.getItem("history"));
      setEmptyFields(false);
      loginUser({
        variables: {
          userInput: {
            username: username,
            password: sha512(password),
          },
        },
      })
        .then((result) => {
          if (result.data.login !== null) {
            const loginData = result?.data?.login;

            if (loginData?.user?.tempPassword) {
              setOpenCambiarPass(true);
              return;
            }

            let rutasPrivadas = routes.filter((route) => route.permiso);
            let rutasPermitidas = rutasPrivadas.filter((route) =>
              loginData?.user?.permisos?.find((item) => item.pantalla === route.permiso)
            );
            localStorage.setItem("user", JSON.stringify({ token: loginData?.token }));
            localStorage.removeItem("history");
            if (history && history.includes("/nodos/")) {
              window.location.href = history;
              return;
            }
            if (history && history.includes("/recepcion/")) {
              window.location.href = history;
              return;
            }
            if (loginData?.user?.id === "1") {
              window.location.href = "/usuarios";
              return;
            }
            if (rutasPermitidas.length > 0) {
              window.location.href = rutasPermitidas[0].route;
              return;
            } else {
              window.location.href = "/soporte";
              return;
            }
          }
        })
        .catch((error) => {
          if (error.message === "Failed to fetch")
            setErrorLogin("No se pudo conectar con el servidor");
          else setErrorLogin(error.networkError.result.errors[0].message);
        });
    } else {
      setEmptyFields(true);
    }
  };

  return (
    <CoverLayout
      title="Bienvenido"
      description="Ingrese su usuario y contraseña para iniciar sesión"
      image={curved9}
    >
      <SoftBox component="form" role="form" autoComplete="off">
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Usuario
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="text"
            placeholder="Usuario"
            name="username"
            value={username}
            onChange={handleChanges}
            error={data && data.authenticateUser === null}
            autoComplete="username"
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Contraseña
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Contraseña"
            name="password"
            value={password}
            onChange={handleChanges}
            error={data && data.authenticateUser === null}
            autoComplete="current-password"
          />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftBox>
            <SoftButton type="submit" color="primary" fullWidth circular onClick={handleLogin}>
              {loading ? <CircularProgress color="inherit" size={17} /> : "Iniciar sesión"}
            </SoftButton>
          </SoftBox>
          {/* <SoftBox mt={2}>
            <SoftButton component={Link} to={"/acceso-alumnos"} color="uproGreen" fullWidth circular>
              Acceso alumnos &nbsp;
              <Icon sx={{ fontWeight: "light" }}>arrow_forward</Icon>
            </SoftButton>
          </SoftBox> */}
          <SoftBox mt={2}>
            <SoftButton
              component={Link}
              to={"/preinscripcion"}
              color="uproGreen"
              fullWidth
              circular
            >
              Preinscripción &nbsp;
              <Icon sx={{ fontWeight: "light" }}>arrow_forward</Icon>
            </SoftButton>
          </SoftBox>
          <SoftBox mt={2}>
          <SoftButton
            variant="text"
            color="uproYellow"
            fullWidth
            circular
            onClick={() => setOpenRecuperarPass(true)}
          >
            Recuperar contraseña &nbsp;
            <Icon sx={{ fontWeight: "light" }}>help</Icon>
          </SoftButton>
        </SoftBox>
        </SoftBox>
        <SoftBox mt={3} display="flex" justifyContent="center" alignItems="center">
          {emptyFields && (
            <SoftTypography variant="caption" color="error">
              Debes ingresar un usuario y contraseña
            </SoftTypography>
          )}
          {!emptyFields && error && (
            <SoftTypography variant="caption" color="error">
              {errorLogin}
            </SoftTypography>
          )}
          {!emptyFields && errorLogin && error === undefined && (
            <SoftTypography variant="caption" color="error">
              {errorLogin}
            </SoftTypography>
          )}
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <InstallPWA />
        </SoftBox>
      </SoftBox>
      <ModalCambiarPass
        open={openCambiarPass}
        handleClose={() => setOpenCambiarPass(false)}
        data={data}
      />
      <ModalNuevaPass
        open={openNuevaPass}
        handleClose={() => setOpenNuevaPass(false)}
        idUser={recoveryData?.idUser}
        codigo={recoveryData?.codigo}
      />
      <ModalRecuperarPass
        open={openRecuperarPass}
        handleClose={() => setOpenRecuperarPass(false)}
        username={username}
        handleChangePass={(response) => {
          setRecoveryData(response);
          setOpenRecuperarPass(false);
          setOpenNuevaPass(true);
        }}
      />
    </CoverLayout>
  );
}

export default SignIn;
