/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const getRows = (data, handleClick) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        icon: (
          <SoftBox
            display="flex"
            alignItems="center"
            py={1}
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick(item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Incidente"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                  }}
                  color={"primary"}
                  fontSize="lg"
                >
                  bug_report
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleClick(item)}>
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {item.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {item.tarea}
            </SoftTypography>
            {item.hayMensajesNuevos && (
              <SoftBox ml={1}>
                <SoftBadge color="uproYellow" badgeContent="Hay mensajes nuevos" />
              </SoftBox>
            )}
          </SoftBox>
        ),
        responsable: (
          <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleClick(item)}>
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {item.nombreUsuarioInterno || "Sin especificar"}
            </SoftTypography>
          </SoftBox>
        ),
        modulo: (
          <SoftBox sx={{ cursor: "pointer" }} onClick={() => handleClick(item)}>
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
            >
              {item.pantalla?.nombre || "Sin especificar"}
            </SoftTypography>
          </SoftBox>
        ),
        prioridad: (
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick(item)}
          >
            <SoftBox>
              <SoftBadge
                variant="gradient"
                badgeContent={item.prioridadSolicitanteUser || "Sin especificar"}
                color={
                  item.prioridadSolicitanteUser === "Baja"
                    ? "info"
                    : item.prioridadSolicitanteUser === "Media"
                    ? "warning"
                    : item.prioridadSolicitanteUser === "Alta"
                    ? "error"
                    : "secondary"
                }
                size="md"
                fullWidth
              />
            </SoftBox>
          </SoftBox>
        ),
        estado: (
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => handleClick(item)}
          >
            <SoftBox>
              <SoftBadge
                variant="gradient"
                badgeContent={
                  item.estadoTicketUser === "Abierto" && item.hayMensajesNuevos
                    ? "Necesita respuesta"
                    : item.estadoTicketUser
                }
                color={
                  item.estadoTicketUser === "Abierto"
                    ? "uproYellow"
                    : item.estadoTicketUser === "Resuelto"
                    ? "uproGreen"
                    : item.estadoTicketUser === "Nuevo"
                    ? "info"
                    : "primary"
                }
                size="md"
                fullWidth
              />
            </SoftBox>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "icon", desc: " ", align: "left", width: "1%" },
  { name: "id", desc: "Nº", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "responsable", desc: "Responsable", align: "left" },
  { name: "modulo", desc: "Módulo", align: "left" },
  { name: "prioridad", desc: "Prioridad", align: "center" },
  { name: "estado", desc: "Estado", align: "center" },
];

export default { columns, getRows };
