// React

// Components
import { Card, Grid, Icon, MenuItem, Select, Tab, Tabs } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Data
import { gql, useQuery } from "@apollo/client";
import SoftButton from "components/SoftButton";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import Certificados from "./components/Certificados";
import DatosPersonales from "./components/DatosPersonales";
import Inscripciones from "./components/Inscripciones";
import Resumen from "./components/Resumen";

function Autogestion() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedCarrera, setSelectedCarrera] = useState(null);
  const { menu } = useContext(MenuContext);
  const [expandCarreras, setExpandCarreras] = useState(false);
  const {
    data: usuario,
    loading,
    refetch,
  } = useQuery(gql`
    query getUser {
      currentUser {
        id
        tipoUser
        username
        nombre
        apellido
        email
        dni
        fechaNacimiento
        pais {
          id
        }
        esDiscapacitado
        discapacidad
        genero
        preferenciaHorario
        poseeBeneficiosSociales
        telefono
        telefonoRespaldo
        tipoEstudios
        localidad {
          id
          nombre
          provincia {
            id
            nombre
          }
        }
        nombreEscuela
        escalaMatematicas
        escalaComprensionTextos
        inscripciones {
          id
          carrera {
            id
            nombre
            codigo
            tipo
          }
          tipo
          createdAt
        }
        documentos {
          id
          nombre
          file
          createdAt
          estado
        }
        inscripcionesExamenes {
          id
          estado
          createdAt
          acta {
            id
            estado
            nota
          }
          examen {
            id
            fecha
            titulo
            duracion
            materia {
              nombre
            }
          }
        }
        inscripcionesComisiones {
          id
          comision {
            id
            nombre
            codigo
            color
            nivel
            fechaInicio 
            fechaFin
            sede {
              id
              nombre
              aulas {
                id
                nombre
                capacidad
              }
            }
            docente {
              id
              nombre
              apellido
            }
            horarios {
              dia
              aula {
                id
                nombre
              }
              capacidad
              horarioInicio
              horarioFin
            }
            materia{
              id
              nombre
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (usuario?.currentUser?.inscripciones.length > 0) {
      setSelectedCarrera(
        usuario.currentUser.inscripciones.filter(
          (inscripcion) =>
            inscripcion.tipo === "Inscripto" ||
            inscripcion.tipo === "Libre" ||
            inscripcion.tipo === "Aprobado"
        ).length
          ? usuario.currentUser.inscripciones.filter(
              (inscripcion) =>
                inscripcion.tipo === "Inscripto" ||
                inscripcion.tipo === "Libre" ||
                inscripcion.tipo === "Aprobado"
            )[0].carrera
          : null
      );
    }
  }, [usuario]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              p={3}
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
            >
              <SoftBox
                mr={2}
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                  width: "100%",
                }}
              >
                <Tabs
                  value={selectedTab}
                  onChange={(e, value) => {
                    setSelectedTab(value);
                    refetch();
                  }}
                >
                  <Tab
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 0 ? "primary" : "secondary"}>
                            description
                          </Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 0 ? "bold" : "regular"}
                        >
                          Resumen
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={0}
                  />
                  <Tab
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 1 ? "primary" : "secondary"}>school</Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 1 ? "bold" : "regular"}
                        >
                          Inscripciones
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={1}
                  />
                  <Tab
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                            account_circle
                          </Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 2 ? "bold" : "regular"}
                        >
                          Datos personales
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={2}
                  />
                  <Tab
                    label={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox display="flex" alignItems="center">
                          <Icon color={selectedTab === 3 ? "primary" : "secondary"}>verified</Icon>
                        </SoftBox>
                        <SoftTypography
                          variant="h6"
                          fontWeight={selectedTab === 3 ? "bold" : "regular"}
                        >
                          Certificados
                        </SoftTypography>
                      </SoftBox>
                    }
                    value={3}
                  />
                </Tabs>
              </SoftBox>
              <SoftBox
                sx={{
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                  width: "100%",
                }}
                mb={2}
              >
                <Select
                  value={selectedTab}
                  onChange={(e) => {
                    setSelectedTab(e.target.value);
                  }}
                >
                  <MenuItem value={0}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 0 ? "primary" : "secondary"}>description</Icon>
                        &nbsp; Resumen
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={1}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 1 ? "primary" : "secondary"}>school</Icon>
                        &nbsp; Inscripciones
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={2}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 2 ? "primary" : "secondary"}>
                          account_circle
                        </Icon>
                        &nbsp; Datos personales
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value={3}>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Icon color={selectedTab === 3 ? "primary" : "secondary"}>verified</Icon>
                        &nbsp; Certificados
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                </Select>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  color="uproYellow"
                  circular
                  sx={{
                    textWrap: "nowrap",
                  }}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={(event) => {
                    setExpandCarreras(true);
                    menu({
                      open: event.currentTarget,
                      align: "right",
                      onClose: () => {
                        setExpandCarreras(false);
                      },
                      options: usuario?.currentUser?.inscripciones
                        .filter(
                          (inscripcion) =>
                            inscripcion.tipo === "Inscripto" ||
                            inscripcion.tipo === "Preinscripto" ||
                            inscripcion.tipo === "Libre"
                        )
                        .map((inscripcion) => ({
                          name: makeNiceText(inscripcion.carrera.nombre, "nombre"),
                          disabled: inscripcion.tipo === "Preinscripto",
                          icon: {
                            icon: inscripcion.carrera.tipo === "Carrera" ? "school" : "menu_book",
                            color:
                              selectedCarrera?.id === inscripcion.carrera.id
                                ? getUproColor("uproYellow")
                                : getUproColor("uproGreen"),
                          },
                          onClick: () => {
                            setSelectedCarrera(inscripcion.carrera);
                          },
                        })),
                    });
                  }}
                >
                  <Icon>school</Icon>
                  &nbsp;{selectedCarrera?.nombre || "Seleccione una carrera"}&nbsp;
                  <Icon
                    sx={{
                      rotate: expandCarreras ? "180deg" : "0deg",
                      transition: "all 0.5s",
                    }}
                  >
                    expand_more
                  </Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <Grid container spacing={2} p={3} pt={0}>
              <Grid item xs={12}>
                {selectedTab === 0 && (
                  <Resumen
                    usuario={usuario?.currentUser}
                    carrera={selectedCarrera}
                    loading={loading}
                    refetch={refetch}
                    setTab={setSelectedTab}
                  />
                )}
                {selectedTab === 1 && (
                  <Inscripciones usuario={usuario?.currentUser} loading={loading} />
                )}
                {selectedTab === 2 && (
                  <DatosPersonales
                    selectedUsuario={usuario?.currentUser}
                    loading={loading}
                    refetch={refetch}
                  />
                )}
                {selectedTab === 3 && (
                  <Certificados
                    usuario={usuario?.currentUser}
                    carrera={selectedCarrera}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Autogestion;
