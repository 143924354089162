// React
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalDocumentacionAdd({ open, handleClose, idCarrera, refetch }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [documento, setDocumento] = useState();
  const inputRef = useRef();
  const [file, setFile] = useState();
  const documentacionRequerida = [
    "Resolución",
  ];

  const [getCarrera, { data, loading }] = useLazyQuery(gql`
    query getCarrera($id: ID!) {
      carrera(id: $id) {
        documentos {
          id
          nombre
          file
          createdAt
        }
      }
    }
  `);

  const [addCarreraFile, { loading: loadingFile }] = useMutation(gql`
    mutation addCarreraFile($idCarrera: ID!, $file: Upload!, $nombre: String!) {
      addCarreraFile(idCarrera: $idCarrera, file: $file, nombre: $nombre) {
        id
      }
    }
  `);

  useEffect(() => {
    setDocumento(null);
    setFile(null);
    if (idCarrera && open) {
      getCarrera({ variables: { id: idCarrera } });
    }
  }, [idCarrera, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Nuevo documento</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Archivo
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    fullWidth
                    label="Archivo"
                    name="tipo"
                    value={documento?.tipo || -1}
                    onChange={(e) => {
                      setDocumento({
                        ...documento,
                        tipo: e.target.value,
                      });
                    }}
                    disabled={loadingFile}
                  >
                    <MenuItem value={-1} disabled>Seleccione un tipo</MenuItem>
                    {documentacionRequerida.map((doc) => (
                      <MenuItem key={doc} value={doc} disabled={data?.usuario?.documentos.find((d) => d.nombre === doc)}>
                        {doc}
                      </MenuItem>
                    ))}
                    <MenuItem value="Otro">Otro</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={
                    documento?.tipo !== "Otro"
                      ? {
                          paddingTop: "0 !important",
                          transition: "padding 0.5s",
                        }
                      : {
                          transition: "padding 0.5s",
                        }
                  }
                >
                  <Collapse in={documento?.tipo === "Otro"}>
                    <InputLabel htmlFor="nombre">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="nombre"
                      placeholder="Ingrese el nombre del documento"
                      type="text"
                      name="nombre"
                      value={documento?.nombre || ""}
                      disabled={loadingFile}
                      onChange={(e) => {
                        setDocumento({
                          ...documento,
                          nombre: e.target.value,
                        });
                      }}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <SoftButton
                    color={file ? "uproBlue" : "uproYellow"}
                    fullWidth
                    circular
                    onClick={() => inputRef.current.click()}
                    disabled={loadingFile}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cloud_upload</Icon>
                    &nbsp;
                    {file
                      ? file.name.length > 40
                        ? `${file.name.substring(0, 40)}...${file.name.substring(
                            file.name.length - 4
                          )}`
                        : file.name
                      : "Subir archivo"}
                  </SoftButton>
                  <input
                    ref={inputRef}
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf, .docx, .jpg, .jpeg, .png"
                    onChange={(e) => {
                     if(e.target.files[0] && e.target.files[0].size < 5000000) {
                        setFile(e.target.files[0]);
                      }else{
                        handleSnackbar("El archivo debe ser menor a 5MB", "error");
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  disabled={
                    loadingFile ||
                    !idCarrera ||
                    !documento?.tipo ||
                    !file ||
                    (documento?.tipo === "Otro" && !documento?.nombre)
                  }
                  onClick={() => {
                    addCarreraFile({
                      variables: {
                        idCarrera: idCarrera,
                        file: file,
                        nombre: documento?.tipo === "Otro" ? documento?.nombre : documento?.tipo,
                      },
                    })
                      .then(() => {
                        handleSnackbar("Archivo subido correctamente", "success");
                        refetch();
                        handleClose();
                        setFile(null);
                        setDocumento(null);
                      })
                      .catch(() => {
                        handleSnackbar("Error al subir el archivo", "error");
                      });
                  }}
                >
                  {loadingFile ? (
                    <CircularProgress
                      size={15}
                      sx={{
                        marginRight: 0.5,
                      }}
                      color="inherit"
                    />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalDocumentacionAdd.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idCarrera: PropTypes.string,
  refetch: PropTypes.func,
};
