// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor, uproColorsList } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalComision({
  open,
  handleClose,
  selectedComision,
  selectedMateria,
  refetch,
}) {
  const { handleSnackbar } = useContext(MessageManager);
  const [comision, setComision] = useState();
  const { menu } = useContext(MenuContext);
  const handleChanges = (e) => {
    setComision({ ...comision, [e.target.name]: e.target.value });
  };

  const [saveComision, { loading: loadingSave }] = useMutation(gql`
    mutation saveComision($input: ComisionInput!) {
      saveComision(input: $input) {
        id
      }
    }
  `);

  const [getSedes, { data: sedes, loading: loadingSedes }] = useLazyQuery(
    gql`
      query getSedes {
        sedes {
          id
          nombre
          aulas {
            id
            nombre
            capacidad
            comisiones {
              id
              horarios {
                dia
                horarioInicio
                horarioFin
                aula {
                  id
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (open) {
      getSedes();
      setComision();
      if (selectedComision) {
        setComision(selectedComision);
      }
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {`Comisión de ${selectedMateria?.nombre}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <InputLabel htmlFor="codigo">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Código
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="codigo"
                    placeholder="Ingrese el código de la comisión"
                    type="text"
                    name="codigo"
                    value={comision?.codigo || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre de la comisión"
                    type="text"
                    name="nombre"
                    value={comision?.nombre || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="sede">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Sede
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="sede"
                    placeholder="Seleccione la sede"
                    name="idSede"
                    value={comision?.idSede || -1}
                    onChange={(e) => {
                      setComision({
                        ...comision,
                        idSede: e.target.value,
                        horarios: comision?.horarios?.map((d) => ({
                          ...d,
                          idAula: null,
                          capacidad: 0,
                          horarioInicio: null,
                          horarioFin: null,
                        })),
                      });
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una sede
                    </MenuItem>
                    {sedes?.sedes?.map((sede) => (
                      <MenuItem key={sede.id} value={sede.id}>
                        {sede.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="docente">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Docente a cargo
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="docente"
                    placeholder="Seleccione el docente a cargo"
                    name="idDocente"
                    value={comision?.idDocente || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione un docente
                    </MenuItem>
                    {selectedMateria?.docentesTitulares?.map((docente) => (
                      <MenuItem key={docente.id} value={docente.id}>
                        {`${docente.apellido}, ${docente.nombre}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    "& .MuiInputBase-root": {
                      paddingRight: "40px !important",
                    },
                  }}
                >
                  <InputLabel htmlFor="nivel">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nivel recomendado
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="docente"
                    placeholder="Seleccione el nivel de la comisión"
                    name="nivel"
                    value={comision?.nivel || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione un nivel
                    </MenuItem>
                    <MenuItem value={"Bajo"}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox mr={2}>
                          <SoftTypography variant="p">Bajo</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("uproPink"),
                            }}
                          >
                            arrow_downward
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                    <MenuItem value={"Medio"}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox mr={2}>
                          <SoftTypography variant="p">Medio</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("uproYellow"),
                            }}
                          >
                            arrow_forward
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                    <MenuItem value={"Alto"}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox mr={2}>
                          <SoftTypography variant="p">Alto</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("uproGreen"),
                            }}
                          >
                            arrow_upward
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    "& .MuiInputBase-root": {
                      paddingRight: "40px !important",
                    },
                  }}
                >
                  <InputLabel htmlFor="color">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Color
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    label="docente"
                    placeholder="Seleccione el color de la comisión"
                    name="color"
                    value={comision?.color || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione un color
                    </MenuItem>
                    {uproColorsList.map((color, index) => (
                      <MenuItem key={index} value={color.color}>
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            width: "100%",
                          }}
                        >
                          <SoftBox mr={2}>
                            <SoftTypography variant="p">{color.name}</SoftTypography>
                          </SoftBox>
                          <SoftBox>
                            <Icon
                              fontSize="small"
                              sx={{
                                verticalAlign: "middle",
                                color: color.color,
                              }}
                            >
                              circle
                            </Icon>
                          </SoftBox>
                        </SoftBox>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <InputLabel htmlFor="color">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha de inicio
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="fechaInicio"
                    placeholder="Ingrese la fecha de inicio"
                    name="fechaInicio"
                    type="date"
                    value={comision?.fechaInicio}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                >
                  <InputLabel htmlFor="color">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha de fin
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="fechaFin"
                    placeholder="Ingrese la fecha de inicio"
                    name="fechaFin"
                    type="date"
                    value={comision?.fechaFin}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Días de cursada
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Autocomplete
                    multiple
                    id="diasSemana"
                    options={[
                      { dia: "Lunes" },
                      { dia: "Martes" },
                      { dia: "Miércoles" },
                      { dia: "Jueves" },
                      { dia: "Viernes" },
                    ].filter((dia) => !comision?.horarios?.find((d) => d.dia === dia.dia))}
                    getOptionLabel={(option) => {
                      return option.dia;
                    }}
                    filterSelectedOptions
                    value={comision?.horarios || []}
                    onChange={(e, value) => {
                      setComision({
                        ...comision,
                        horarios: value.map((d) => ({ ...d })),
                      });
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                    sx={{
                      ".MuiAutocomplete-inputRoot": {
                        padding: `0 !important`,
                      },
                    }}
                  />
                </Grid>
                {comision?.horarios?.map((dia, index) => (
                  <Grid item xs={12} container spacing={2} key={dia.dia}>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nombre">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Dia
                        </SoftTypography>
                      </InputLabel>
                      <SoftInput type="text" value={dia.dia} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nombre">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Aula
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        label="aula"
                        placeholder="Seleccione el aula"
                        name="idAula"
                        value={dia.idAula || -1}
                        onChange={(e) => {
                          setComision({
                            ...comision,
                            horarios: comision.horarios.map((d, i) => {
                              if (d.dia === dia.dia) {
                                return {
                                  ...d,
                                  idAula: e.target.value,
                                  capacidad:
                                    sedes?.sedes
                                      ?.find((sede) => sede.id === comision.idSede)
                                      ?.aulas?.find((aula) => aula.id === e.target.value)
                                      ?.capacidad || 0,
                                };
                              }
                              return d;
                            }),
                          });
                        }}
                      >
                        <MenuItem value={-1} disabled>
                          Seleccione un aula
                        </MenuItem>
                        {sedes?.sedes
                          ?.find((sede) => sede.id === comision.idSede)
                          ?.aulas?.map((aula) => (
                            <MenuItem key={aula.id} value={aula.id}>
                              {aula.nombre}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <InputLabel htmlFor="nombre">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          {`Capacidad (Aula: ${
                            sedes?.sedes
                              ?.find((sede) => sede.id === comision.idSede)
                              ?.aulas?.find((aula) => aula.id === dia.idAula)?.capacidad || 0
                          })`}
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                      <SoftInputNumber
                        value={dia.capacidad}
                        onChange={(e) => {
                          setComision({
                            ...comision,
                            horarios: comision.horarios.map((d, i) => {
                              if (d.dia === dia.dia) {
                                return { ...d, capacidad: e.target.value };
                              }
                              return d;
                            }),
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <InputLabel htmlFor="horarioInicio">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Horario de inicio
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        label="horarioInicio"
                        placeholder="Seleccione el horario de inicio"
                        name="horarioInicio"
                        value={dia.horarioInicio || -1}
                        onChange={(e) => {
                          setComision({
                            ...comision,
                            horarios: comision.horarios.map((d, i) => {
                              if (d.dia === dia.dia) {
                                return { ...d, horarioInicio: e.target.value };
                              }
                              return d;
                            }),
                          });
                        }}
                        sx={{
                          border:
                            dia.horarioInicio &&
                            dia.horarioFin &&
                            (dayjs(dia.horarioInicio, "HH:mm").isAfter(
                              dayjs(dia.horarioFin, "HH:mm")
                            ) ||
                              dia.horarioInicio === dia.horarioFin) &&
                            "1px solid red !important",
                        }}
                        disabled={!dia.idAula}
                      >
                        <MenuItem value={-1} disabled>
                          Seleccione
                        </MenuItem>
                        {dia?.idAula &&
                          new Array(17).fill(0).map((_, index) => {
                            const horariosDia = sedes?.sedes
                              ?.find((sede) => sede.id === comision.idSede)
                              ?.aulas?.find((aula) => aula.id === dia.idAula)
                              ?.comisiones?.map((comision) => comision.horarios)
                              ?.flat()
                              ?.filter((d) => d.dia === dia.dia && d.aula.id === dia.idAula);

                            const thisHorario = dayjs()
                              .startOf("day")
                              .add(index + 7, "hour")
                              .format("HH:mm");

                            let horariosDeshabilitados = [];
                            horariosDia?.forEach((d) => {
                              const inicio = dayjs(d.horarioInicio, "HH:mm:ss").hour();
                              const fin = dayjs(d.horarioFin, "HH:mm:ss").hour();

                              for (let i = inicio; i < fin; i++) {
                                horariosDeshabilitados.push(i);
                              }
                            });

                            return (
                              <MenuItem
                                key={index}
                                value={thisHorario}
                                disabled={horariosDeshabilitados.includes(
                                  dayjs(thisHorario, "HH:mm").hour()
                                )}
                              >
                                {thisHorario}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <InputLabel htmlFor="horarioFin">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Horario de fin
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                      <Select
                        label="horarioFin"
                        placeholder="Seleccione el horario de fin"
                        name="horarioFin"
                        value={dia.horarioFin || -1}
                        disabled={!dia.horarioInicio || !dia.idAula}
                        onChange={(e) => {
                          setComision({
                            ...comision,
                            horarios: comision.horarios.map((d, i) => {
                              if (d.dia === dia.dia) {
                                return { ...d, horarioFin: e.target.value };
                              }
                              return d;
                            }),
                          });
                        }}
                        sx={{
                          border:
                            dia.horarioInicio &&
                            dia.horarioFin &&
                            (dayjs(dia.horarioInicio, "HH:mm").isAfter(
                              dayjs(dia.horarioFin, "HH:mm")
                            ) ||
                              dia.horarioInicio === dia.horarioFin) &&
                            "1px solid red !important",
                        }}
                      >
                        <MenuItem value={-1} disabled>
                          Seleccione
                        </MenuItem>
                        {dia?.idAula &&
                          new Array(17).fill(0).map((_, index) => {
                            const horariosDia = sedes?.sedes
                              ?.find((sede) => sede.id === comision.idSede)
                              ?.aulas?.find((aula) => aula.id === dia.idAula)
                              ?.comisiones?.map((comision) => comision.horarios)
                              ?.flat()
                              ?.filter((d) => d.dia === dia.dia && d.aula.id === dia.idAula);

                            const thisHorario = dayjs()
                              .startOf("day")
                              .add(index + 7, "hour")
                              .format("HH:mm");

                            let horariosDeshabilitados = [dayjs("08:00", "HH:mm").format("HH:mm")];

                            horariosDia?.forEach((d) => {
                              const inicio = dayjs(d.horarioInicio, "HH:mm:ss").hour();
                              const fin = dayjs(d.horarioFin, "HH:mm:ss").hour();

                              for (let i = inicio + 1; i <= fin; i++) {
                                horariosDeshabilitados.push(
                                  dayjs().startOf("day").add(i, "hour").format("HH:mm")
                                );
                              }

                              // Bloquear todas las horas que queden del día desde "fin" si dia.horarioInicio es menor a "inicio"
                              if (
                                dayjs(dia.horarioInicio, "HH:mm").isBefore(
                                  dayjs(d.horarioInicio, "HH:mm")
                                )
                              ) {
                                for (let i = fin; i < 24; i++) {
                                  horariosDeshabilitados.push(
                                    dayjs().startOf("day").add(i, "hour").format("HH:mm")
                                  );
                                }
                              }
                            });

                            return (
                              <MenuItem
                                key={index}
                                value={thisHorario}
                                disabled={
                                  thisHorario === dia.horarioInicio ||
                                  horariosDeshabilitados.includes(
                                    dayjs(thisHorario, "HH:mm").format("HH:mm")
                                  ) ||
                                  dayjs(thisHorario, "HH:mm").isBefore(
                                    dayjs(dia.horarioInicio, "HH:mm")
                                  )
                                }
                              >
                                {thisHorario}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <SoftBox display="flex" justifyContent="end" pt={3}>
                <SoftBox mr={2}>
                  <SoftButton
                    color="primary"
                    circular
                    disabled={loadingSave}
                    onClick={() => {
                      setComision();
                      handleClose();
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>close</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox>
                  <SoftButton
                    color="uproGreen"
                    circular
                    disabled={
                      loadingSave ||
                      !comision?.codigo ||
                      !comision?.nombre ||
                      !comision?.horarios ||
                      !comision?.idSede ||
                      !comision?.idDocente ||
                      !comision?.color ||
                      !comision?.nivel ||
                      !comision?.fechaInicio ||
                      !comision?.fechaFin ||
                      comision?.horarios.some(
                        (d) =>
                          !d.horarioInicio ||
                          !d.horarioFin ||
                          !d.idAula ||
                          !d.capacidad ||
                          dayjs(d.horarioInicio, "HH:mm").isAfter(dayjs(d.horarioFin, "HH:mm"))
                      )
                    }
                    onClick={() => {
                      saveComision({
                        variables: {
                          input: {
                            id: comision?.id,
                            nombre: comision.nombre,
                            codigo: comision.codigo,
                            idSede: comision.idSede,
                            color: comision.color,
                            nivel: comision.nivel,
                            idDocente: comision.idDocente,
                            fechaInicio: dayjs(comision.fechaInicio).format("YYYY-MM-DD"),
                            fechaFin: dayjs(comision.fechaFin).format("YYYY-MM-DD"),
                            horarios: comision.horarios.map((d) => ({
                              dia: d.dia,
                              horarioInicio: d.horarioInicio,
                              horarioFin: d.horarioFin,
                              idAula: d.idAula,
                              capacidad: d.capacidad,
                            })),
                            idMateria: selectedMateria.id,
                          },
                        },
                      })
                        .then(() => {
                          handleSnackbar("Comisión guardada correctamente", "success");
                          setComision(null);
                          refetch();
                          handleClose();
                        })
                        .catch((e) => {
                          handleSnackbar(e.message || "Error al guardar la comisión", "error");
                        });
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress
                        size={15}
                        sx={{
                          marginRight: 0.5,
                        }}
                        color="inherit"
                      />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalComision.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedComision: PropTypes.object,
  selectedMateria: PropTypes.object,
  refetch: PropTypes.func,
};
