import {
  Backdrop,
  Card,
  CircularProgress,
  Fade,
  Icon,
  Modal,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { gql, useMutation } from "@apollo/client";
import { MessageManager } from "context";
import { API_URL } from "services/config";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", md: "40%", lg: "35%", xl: "30%" },
  py: 4,
  px: 4,
  overflow: "auto",
};

export default function ModalCambiarPass({ open, handleClose, data }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    setLoading(true);
    let mutation = `mutation updatePass($idUser: ID, $password: String!) {
      saveUser(input: {id: $idUser, password: $password}) {
        id
      }
    }`;

    let variables = {
      idUser: data?.login?.user?.id,
      password: password,
    };
    
    axios
    .post(API_URL + "/graphql", {
      query: mutation,
      variables: variables,
    }, {
      headers: {
        Authorization: data?.login?.token,
      },
    }).then((response) => {
      if (response.data.errors) {
        handleSnackbar("Error al actualizar la contraseña", "error");
      } else {
        handleSnackbar("Contraseña actualizada, vuelve a iniciar sesión", "success");
        handleClose();
        setLoading(true);
      }
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox display="flex">
                <SoftTypography variant="h4" color="dark" fontWeight="bold">🙋‍♂️ Hola</SoftTypography>
                <SoftTypography variant="h4" color="primary" fontWeight="bold">&nbsp;{data?.login?.user?.nombre || data?.login?.user?.username}</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </SoftBox>
            </SoftBox>
            <SoftBox mt={2}>
              <SoftTypography fontSize="medium">Para comenzar, queremos asegurarnos de tu seguridad. Por favor, ingresa una nueva contraseña para proteger tu cuenta y tus experiencias futuras.</SoftTypography>
            </SoftBox>
            <SoftBox mt={2}>
              <Tooltip 
                title={!(/^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(password)) ? "Almenos 8 caracteres, 1 mayúscula y 1 número" : ""} 
                open={true} 
                placement="top"
              >
                <SoftInput
                  placeholder="Nueva contraseña"
                  type="password"
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Tooltip>
            </SoftBox>
            <SoftBox mt={2}>
              <Tooltip 
                title={(/^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(password)) && password != repeatPassword ? "Las contraseñas deben coincidir" : ""}
                open={true}
                placement="top"
              >
                <SoftInput
                  placeholder="Repetir contraseña"
                  type="password"
                  fullWidth
                  onChange={(e) => setRepeatPassword(e.target.value)}
                />
              </Tooltip>
            </SoftBox>
            <SoftBox mt={2}>
              <SoftButton
                color="primary"
                fullWidth
                circular
                onClick={handleSubmit}
                disabled={password != repeatPassword || !(/^(?=.*[A-Z])(?=.*[0-9]).{8,}$/.test(password)) || loading}
              >
                {loading && (<><CircularProgress color="white" size={20} />&nbsp;&nbsp;</>)}
                Cambiar contraseña
              </SoftButton>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalCambiarPass.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object,
};