import { useEffect, useContext } from "react";

// react-router-dom components
import { Link, NavLink, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import LinkMUI from "@mui/material/Link";
import List from "@mui/material/List";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Kaizen Dashboard examples
import SidenavCollapse from "components/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";

// Kaizen Dashboard context
import { setMiniSidenav, useSoftUIController } from "context";
import { UserContext } from "context/user";
import { UiContext } from "context/ui";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { user } = useContext(UserContext);
  const { hideNavbar } = useContext(UiContext);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, route, href, permiso }) => {
      let returnValue;
      if (
        type === "collapse" &&
        user &&
        (user?.tipoUser === "Administrador" || user?.permisos?.find((item) => item.pantalla === permiso) || !permiso)
      ) {
        returnValue = href ? (
          <LinkMUI
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </LinkMUI>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavCollapse
              color={color}
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      if (user) {
        if (user?.id) {
          return returnValue;
        } else if (user.id === "1") {
          return returnValue;
        }
      }
      return null;
    }
  );

  return (
    !hideNavbar && (
      <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <SoftBox pt={3} pb={1} px={4} textAlign="center">
        <SoftBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <SoftTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </SoftTypography>
        </SoftBox>
        <SoftBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <SoftBox component="img" src={brand} alt="UPrO Logo" width="100%" />}
        </SoftBox>
      </SoftBox>
      <Divider />
      <List
        sx={{
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        {renderRoutes}
      </List>
      <SoftBox pt={2} my={2} mx={2} mt="auto">
        <SoftBox mt={2}>
          <SoftButton
            component={Link}
            to="/soporte"
            variant="gradient"
            color="dark"
            fullWidth
            circular
          >
            <Icon sx={{ fontWeight: "bold" }}>report_problem</Icon>
            &nbsp; Reportar error
          </SoftButton>
        </SoftBox>
        <SoftBox mt={2}>
          <SoftButton
            component="a"
            href="https://uprosanluis.edu.ar/"
            target="_blank"
            rel="noreferrer"
            variant="gradient"
            color={color}
            fullWidth
            circular
          >
            <Icon sx={{ fontWeight: "bold" }}>arrow_outward</Icon>
            &nbsp; UPrO Web
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SidenavRoot>
  )
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "primary",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "terraPrimary",
    "terraSecondary",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
