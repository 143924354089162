/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data, handleHabilitarExamen, handleVerExamen, handleFinalizarExamen) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{}}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("uproBlue"),
                }}
                fontSize="lg"
              >
                person
              </Icon>
            }
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Alumno"} placement="top">
                <Icon
                  color="primary"
                  sx={{
                    verticalAlign: "middle",
                  }}
                  fontSize="lg"
                >
                  person
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{}}
            data={makeNiceText(`${item?.alumno?.apellido} ${item?.alumno?.nombre}`, "nombre")}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(`${item?.alumno?.apellido} ${item?.alumno?.nombre}`, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        estado: (
          <SoftBox sx={{}} data={item?.estado}>
            <SoftBadge
              color={
                item?.estado === "Deshabilitado"
                  ? "secondary"
                  : item?.estado === "Habilitado"
                  ? "uproYellow"
                  : item?.estado === "Finalizado"
                  ? "uproGreen"
                  : "primary"
              }
              badgeContent={item?.estado}
            />
          </SoftBox>
        ),
        nota:
          item?.estado === "Finalizado" ? (
            <SoftBox data={item?.nota}>
              <SoftTypography
                verticalAlign="text-top"
                variant="caption"
                color="secondary"
                fontWeight="medium"
              >
                {item?.nota}
              </SoftTypography>
            </SoftBox>
          ) : (
            <SoftBox></SoftBox>
          ),
        estadoExamen: item?.acta ? (
          <SoftBox data={item?.acta?.estado}>
            <SoftBadge
              color={
                item?.acta?.estado === "Aprobado"
                  ? "uproGreen"
                  : item?.acta?.estado === "Desaprobado"
                  ? "primary"
                  : "primary"
              }
              badgeContent={item?.acta?.estado}
            />
          </SoftBox>
        ) : item?.estado === "Finalizado" || item?.estado === "Ausente" ? (
          <SoftBox
            data={
              item?.nota < 60
                ? "Desaprobado"
                : item?.nota >= 60 && item?.nota < 70
                ? "Requiere revisión"
                : "Aprobado"
            }
          >
            <SoftBadge
              color={
                item?.nota < 60
                  ? "primary"
                  : item?.nota >= 60 && item?.nota < 70
                  ? "uproYellow"
                  : "uproGreen"
              }
              badgeContent={
                item?.nota < 60
                  ? "Desaprobado"
                  : item?.nota >= 60 && item?.nota < 70
                  ? "Requiere revisión"
                  : "Aprobado"
              }
            />
          </SoftBox>
        ) : (
          <SoftBox></SoftBox>
        ),
        accion: (
          <SoftBox mr={2}>
            {item?.estado === "Deshabilitado" && (
              <Tooltip
                title={
                  dayjs(item?.examen?.fecha).endOf("day").isBefore(dayjs())
                    ? "La fecha de examen ya pasó"
                    : dayjs(item?.examen?.fecha).startOf("day").isAfter(dayjs())
                    ? "Aún no es la fecha de examen"
                    : "Habilitar examen"
                }
                placement="top"
              >
                <SoftBox>
                  <SoftButton
                    color="primary"
                    circular
                    iconOnly
                    disabled={
                      dayjs(item?.examen?.fecha).endOf("day").isBefore(dayjs()) ||
                      dayjs(item?.examen?.fecha).startOf("day").isAfter(dayjs())
                    }
                    onClick={(e) => handleHabilitarExamen(item)}
                  >
                    <Icon>lock_open</Icon>
                  </SoftButton>
                </SoftBox>
              </Tooltip>
            )}
            {item?.estado === "Finalizado" && (
              <Tooltip title={"Ver exámen"} placement="top">
                <SoftBox>
                  <SoftButton
                    color="uproYellow"
                    circular
                    iconOnly
                    onClick={(e) => handleVerExamen(item)}
                  >
                    <Icon>visibility</Icon>
                  </SoftButton>
                </SoftBox>
              </Tooltip>
            )}
            {item?.estado === "En curso" && (
              <Tooltip title={"Finalizar exámen"} placement="top">
                <SoftBox>
                  <SoftButton
                    color="uproGreen"
                    circular
                    iconOnly
                    onClick={(e) => handleFinalizarExamen(item)}
                  >
                    <Icon>done</Icon>
                  </SoftButton>
                </SoftBox>
              </Tooltip>
            )}
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "estado", desc: "Estado", align: "left", noOrder: true },
  { name: "nota", desc: "Nota", align: "left", noOrder: true },
  { name: "estadoExamen", desc: "Estado Exámen", align: "left", noOrder: true },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
