// React

// Components
import { Card, Collapse, Grid, Icon, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import SoftTypography from "components/SoftTypography";

// Data
import ProfileCard from "components/Cards/ProfileCard";
import { useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import SinDatos from "components/Carteles/SinDatos";
import SoftInput from "components/SoftInput";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import CustomPagination from "components/Pagination";
import { gql, useQuery } from "@apollo/client";
import dataAlumnos from "layouts/autogestion-docente/data/dataAlumnos";

export default function Alumnos({ materia }) {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [filterComision, setFilterComision] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const { columns, getRows } = dataAlumnos;
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombreCompleto",
    orden: "ASC",
  });

  const { data, loading } = useQuery(
    gql`
      query getAlumnosComisiones(
        $idsComisiones: [ID!]
        $filter: JSON
        $search: String
        $page: Int
        $order: Order
      ) {
        paginationInfo {
          pages
          total
        }
        alumnosComisiones(
          idsComisiones: $idsComisiones
          filter: $filter
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          nombre
          apellido
          dni
          asistencias {
            presentes
            ausentes
            justificados
          }
          inscripcionesComisiones(idsComisiones: $idsComisiones) {
            id
            comision {
              id
              codigo
              nombre
            }
          }
        }
      }
    `,
    {
      variables: {
        idsComisiones: filterComision || materia?.comisiones?.map((comision) => comision.id),
        filter: filter,
        search: search,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        limit: 10,
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.alumnosComisiones));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <SoftBox>
      <Card>
        <Grid container spacing={2} p={3}>
          <Grid item xs={12}>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                <SoftTypography variant="h6">Alumnos</SoftTypography>
              </SoftBox>
              <SoftBox
                display={
                  {
                    xs: "flex-col",
                    sm: "flex",
                  }
                }
                alignItems="center"
                justifyContent="end"
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftBox
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                >
                  <SoftInput
                    placeholder="Escribe aquí..."
                    icon={{ component: "search", direction: "left" }}
                    onChange={handleSearch}
                  />
                </SoftBox>
                <SoftBox
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "auto",
                    },
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                  ml={{
                    xs: 0,
                    sm: 2,
                  }}
                >
                  <Select
                    value={filterComision || -1}
                    onChange={(e) => {
                      if (e.target.value === -1) {
                        setFilterComision(null);
                      } else {
                        setFilterComision(e.target.value);
                      }
                    }}
                    >
                    <MenuItem value={-1}>Todas las comisiones</MenuItem>
                    {materia?.comisiones?.map((comision) => (
                      <MenuItem key={comision.id} value={comision.id}>
                        {`${comision.codigo} - ${comision.nombre}`}
                      </MenuItem>
                    ))}
                    </Select>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          {loading ? (
            <Loading />
          ) : rows?.length > 0 ? (
            <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
          ) : (
            <SinDatos />
          )}
        </SoftBox>
        <CustomPagination
          loading={loading}
          length={data?.alumnosComisiones?.length}
          total={pagesInfo?.total}
          pages={pagesInfo?.pages}
          page={page}
          setPage={setPage}
          noCard
        />
      </Card>
    </SoftBox>
  );
}

Alumnos.propTypes = {
  materia: PropTypes.object,
};
