import { useQuery } from "@apollo/client";
import {
  Card,
  CircularProgress,
  Collapse,
  CssBaseline,
  Fade,
  Grid,
  Icon,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import brand from "assets/images/logo.png";
import theme from "assets/theme";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import colors from "assets/theme/base/colors";
import ErrorMessager from "components/ErrorMessager";
import InstallPWA from "components/InstallModal";
import Sidenav from "components/Sidenav";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { MessageManager, setMiniSidenav, useSoftUIController } from "context";
import { ConfirmarProvider } from "context/ConfirmarContext";
import { ModalProvider } from "context/modal";
import { MenuProvider } from "context/menuContext";
import { UserContext } from "context/user";
import DeviceDetector from "device-detector-js";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "routes";
import { GET_USER } from "services/login";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { snackbar, snackbarMessage, snackbarType, handleSnackbar, closeSnackbar } =
    useContext(MessageManager);
  const { miniSidenav, direction, layout, openConfigurator, openOnline, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [installOpen, setInstallOpen] = useState(true);
  const { borderRadius } = borders;
  const { lg } = boxShadows;
  const [snackbarInterval, setSnackbarInterval] = useState(null);

  useEffect(() => {
    if (snackbar) {
      if (snackbarInterval) {
        clearTimeout(snackbarInterval);
      }
      setSnackbarInterval(
        setTimeout(() => {
          closeSnackbar();
        }, 6000)
      );
    }
  }, [snackbar]);

  const { data, loading, error, refetch } = useQuery(GET_USER);

  const validatePath = (route, pathname) => {
    if (route.includes(":")) {
      let routeArray = route.split("/");
      let pathnameArray = pathname.split("/");
      if (
        routeArray.length === pathnameArray.length &&
        pathnameArray[pathnameArray.length - 1] !== ""
      ) {
        for (let i = 0; i < routeArray.length; i++) {
          if (routeArray[i] !== pathnameArray[i] && !routeArray[i].includes(":")) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  };

  const currentRoute = routes.find(
    (route) => route.route === pathname || validatePath(route.route, pathname)
  );
  const { handleUser, logout } = useContext(UserContext);

  useEffect(() => {
    if (data?.currentUser) {
      handleUser(data.currentUser);

      if (!currentRoute && pathname !== "/") {
        logout();
        return;
      }

      if (data?.currentUser?.tipoUser === "Administrador") {
        console.log("---------- ADMIN MODE ----------");
        if (pathname === "/") {
          window.location.href = "/usuarios";
        }
        return;
      }

      if (
        (currentRoute?.permiso &&
          !data?.currentUser?.permisos?.find((item) => item.pantalla === currentRoute?.permiso)) ||
        pathname === "/"
      ) {
        let rutasPrivadas = routes.filter((route) => route.permiso);
        let rutasPermitidas = rutasPrivadas.filter((route) =>
          data?.currentUser?.permisos?.find((item) => item.pantalla === route.permiso)
        );
        if (rutasPermitidas.length > 0) {
          handleSnackbar(
            "No tiene permisos para acceder a esta pantalla, será redirigido...",
            "error"
          );
          window.location.href = rutasPermitidas[0].route;
          return;
        } else {
          logout();
          return;
        }
      }
    } else if (error) {
      if (
        pathname !== "/login" &&
        pathname !== "/preinscripcion" &&
        !pathname.includes("/certificado/")
      ) {
        localStorage.setItem("history", JSON.stringify(pathname));
        handleSnackbar(
          "Se ha vencido la sesión, deberá iniciar sesión nuevamente. Será redirigido...",
          "error"
        );
        logout();
      }
      return;
    }
  }, [pathname, data]);

  useEffect(() => {
    refetch();
  }, [pathname]);

  //set loading spinner to false
  useEffect(() => {
    if (!loading) {
      setLoadingSpinner(false);
    }
  }, [loading]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route exact path={route.route} element={route.component} key={"route-" + route.key} />
        );
      }

      return null;
    });

  return loadingSpinner ? (
    <ThemeProvider theme={theme}>
      <SoftBox display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Fade in={loadingSpinner} appear={true} timeout={1000}>
          <CircularProgress />
        </Fade>
      </SoftBox>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <ConfirmarProvider>
        <ModalProvider>
          <MenuProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {device.device.type !== "desktop" && installOpen && (
                <InstallPWA setInstallOpen={setInstallOpen} />
              )}
              <SoftBox
                mb={3}
                mr={3}
                sx={{
                  position: "fixed",
                  right: 0,
                  bottom: 0,
                  zIndex: 2000,
                  boxShadow: lg,
                  borderRadius: borderRadius.lg,
                }}
              >
                <Collapse in={snackbar} timeout={500}>
                  <Card>
                    <SoftBox
                      sx={{
                        width: { xs: "90vw", sm: "300px" },
                      }}
                      p={2}
                      onMouseEnter={() => clearTimeout(snackbarInterval)}
                      onMouseLeave={() => {
                        setSnackbarInterval(
                          setTimeout(() => {
                            closeSnackbar();
                          }, 3000)
                        );
                      }}
                    >
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="start"
                        textAlign="start"
                      >
                        <SoftBox display="flex-row" alignItems="center">
                          <SoftBox lineHeight={1} display="flex" alignItems="center">
                            <SoftBox mr={1}>
                              <Icon color={snackbarType} fontSize="small">
                                {snackbarType === "error"
                                  ? "error"
                                  : snackbarType === "success"
                                  ? "check_circle"
                                  : snackbarType === "warning"
                                  ? "warning"
                                  : "info"}
                              </Icon>
                            </SoftBox>

                            <SoftTypography variant="h7" fontWeight="bold" color={snackbarType}>
                              {snackbarType === "error"
                                ? "Error"
                                : snackbarType === "success"
                                ? "Éxito"
                                : snackbarType === "warning"
                                ? "Atención"
                                : "Información"}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox display="flex">
                          <Tooltip title="Cerrar" placement="top">
                            <Icon
                              fontSize="medium"
                              onClick={closeSnackbar}
                              sx={{
                                cursor: "pointer",
                                zIndex: 9999,
                              }}
                            >
                              close
                            </Icon>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <SoftBox
                            sx={{
                              lineHeight: 1,
                            }}
                          >
                            <SoftTypography variant="caption">{snackbarMessage}</SoftTypography>
                          </SoftBox>
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </Collapse>
              </SoftBox>

              <CssBaseline />
              {layout === "dashboard" && currentRoute !== undefined && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brand={brand}
                    brandName="UPrO"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />

                  <ErrorMessager />
                </>
              )}
              <Routes>{getRoutes(routes)}</Routes>
            </LocalizationProvider>
          </MenuProvider>
        </ModalProvider>
      </ConfirmarProvider>
    </ThemeProvider>
  );
}
