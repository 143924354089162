// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Fade, Grid, Icon, Menu, MenuItem, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import ModalDelete from "components/Modals/Delete";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import dataExamenes from "layouts/carreras/data/dataExamenes";
import { getUproColor } from "utils/colors";
import ModalAula from "../ModalAula";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

export default function ModalExamenes({ open, handleClose, selectedMateria }) {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedExamen, setSelectedAula] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "titulo",
    orden: "ASC",
  });
  const navigate = useNavigate();

  const { handleSnackbar } = useContext(MessageManager);
  const { columns, getRows } = dataExamenes;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedAula(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteAula, { loading: loadingDelete }] = useMutation(
    gql`
      mutation deleteAula($id: ID!) {
        deleteAula(id: $id) {
          id
        }
      }
    `
  );

  const [getExamenes, { loading, error, data, refetch: refetchExamenes }] = useLazyQuery(
    gql`
      query getExamenes($search: String, $page: Int, $order: Order, $filter: JSON) {
        paginationInfo {
          pages
          total
        }
        examenes(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          titulo
          fecha
          duracion
          cantidadInscripciones
          createdBy {
            id
            nombre
            apellido
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      <MenuItem
        disabled={false}
        onClick={() => {
          navigate(`/carreras/creador-examenes`, {
            state: {
              materia: selectedMateria,
              idExamen: selectedExamen?.id,
              readOnly: selectedExamen?.cantidadInscripciones > 0,
            },
          });
        }}
      >
        <Tooltip
          title={selectedExamen?.cantidadInscripciones > 0 ? "Los exámenes con inscriptos son de solo lectura" : ""}
          placement="top"
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox>
              <SoftTypography variant="p">
                {selectedExamen?.cantidadInscripciones > 0 ? "Ver" : "Editar"}
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("uproGreen"),
                }}
              >
                {selectedExamen?.cantidadInscripciones > 0 ? "visibility" : "edit"}
              </Icon>
            </SoftBox>
          </SoftBox>
        </Tooltip>
      </MenuItem>
      <MenuItem disabled>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Eliminar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproPink"),
              }}
            >
              delete
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (data) {
      setRows(getRows(data.examenes, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  useEffect(() => {
    if (open && selectedMateria) {
      getExamenes({
        variables: {
          search: search || null,
          page: page,
          order: { field: orderBy.campo, order: orderBy.orden },
          filter: {
            idMateria: selectedMateria.id,
          },
        },
      });
    }
  }, [open, page]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftTypography variant="h6">Exámenes</SoftTypography>
                      <SoftBox
                        display={{
                          xs: "block",
                          sm: "none",
                        }}
                      >
                        <Tooltip title="Agregar nuevo exámen" placement="top">
                          <Link
                            to="/carreras/creador-examenes"
                            state={{ materia: selectedMateria }}
                          >
                            <SoftButton color="primary" circular iconOnly>
                              <Icon>add</Icon>
                            </SoftButton>
                          </Link>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftBox
                        mr={{
                          xs: 0,
                          sm: 2,
                        }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "auto",
                          },
                        }}
                      >
                        <SoftInput
                          placeholder="Escribe aquí..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                        />
                      </SoftBox>
                      <SoftBox
                        display={{
                          xs: "none",
                          sm: "block",
                        }}
                      >
                        <Tooltip title="Agregar nuevo exámen" placement="top">
                          <Link
                            to="/carreras/creador-examenes"
                            state={{ materia: selectedMateria }}
                          >
                            <SoftButton color="primary" circular>
                              <Icon>add</Icon>
                              &nbsp;Agregar
                            </SoftButton>
                          </Link>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                    order={orderBy}
                    setOrder={handleOrderBy}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={data?.examenes.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </Card>
            {renderMenu}
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalExamenes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedMateria: PropTypes.object,
};
