// @mui material components
import {
  Card,
  Collapse,
  Fade,
  Grid,
  Icon,
  Modal,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// Kaizen Dashboard components
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import PieChart from "components/Charts/PieChart";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor, uproColors } from "utils/colors";
import SoftBadge from "components/SoftBadge";
import SinDatos from "components/Carteles/SinDatos";
import Loading from "components/Carteles/Loading";
import * as XLSX from "xlsx";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "70%" },
  overflow: "auto",
  py: 4,
};

export default function ModalEstadoInscripciones({ open, handleClose }) {
  const [selectedSede, setSelectedSede] = useState(null);
  const [expandSedes, setExpandSedes] = useState(false);
  const { menu } = useContext(MenuContext);
  const [reporteEstado, setReporteEstado] = useState({});
  const [reporteConfirmados, setReporteConfirmados] = useState(null);

  const [getReporte, { loading }] = useLazyQuery(
    gql`
      query getReporte($idSede: ID) {
        reporteEstado(idSede: $idSede) {
          totalInscripciones
          totalInscripcionesEstado {
            cantidad
            estado
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: sedes } = useQuery(
    gql`
      query getSedes {
        sedes {
          id
          nombre
          aulas {
            id
            nombre
            capacidad
          }
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (open) {
      getReporte({
        variables: {
          idSede: selectedSede?.id || undefined,
        },
      }).then((data) => {
        setReporteEstado(data?.data?.reporteEstado);
        setReporteConfirmados({
          confirmados:
            data?.data?.reporteEstado?.totalInscripcionesEstado
              ?.filter((estado) => estado.estado === "Inscripto" || estado.estado === "Libre")
              ?.map((estado) => estado.cantidad)
              .reduce((acc, curr) => acc + curr, 0) || 0,
          noConfirmados:
            data?.data?.reporteEstado?.totalInscripcionesEstado?.find(
              (estado) => estado.estado === "Preinscripto"
            )?.cantidad || 0,
          baja:
            data?.data?.reporteEstado?.totalInscripcionesEstado?.find(
              (estado) => estado.estado === "Baja"
            )?.cantidad || 0,
        });
      });
    }
  }, [open, selectedSede]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
            <SoftTypography variant="h6">Reporte de estado de inscripciones</SoftTypography>
            <SoftBox display="flex" alignItems="center">
              <SoftBox mr={2}>
                <SoftButton
                  color="uproGreen"
                  circular
                  onClick={(event) => {
                    setExpandSedes(!expandSedes);
                    menu({
                      open: event.currentTarget,
                      align: "right",
                      onClose: () => {
                        setExpandSedes(false);
                      },
                      options: [
                        {
                          name: "Todas las sedes",
                          icon: {
                            icon: selectedSede ? "location_city" : "location_on",
                            color: selectedSede
                              ? getUproColor("uproGreen")
                              : getUproColor("uproPink"),
                          },
                          onClick: () => {
                            setSelectedSede(null);
                          },
                        },
                        ...sedes?.sedes?.map((sede) => ({
                          name: sede.nombre,
                          icon: {
                            icon: selectedSede?.id === sede.id ? "location_on" : "location_city",
                            color:
                              selectedSede?.id === sede.id
                                ? getUproColor("uproPink")
                                : getUproColor("uproGreen"),
                          },
                          onClick: () => {
                            setSelectedSede(sede);
                          },
                        })),
                      ],
                      handleClose: () => {
                        setExpandSelector(false);
                      },
                    });
                  }}
                >
                  <Icon fontSize="small">apartment</Icon>
                  &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                  <Icon
                    sx={{
                      rotate: expandSedes ? "180deg" : "0deg",
                      transition: "all 0.5s",
                    }}
                  >
                    expand_more
                  </Icon>
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <Icon
                  sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                    fontSize: `${size.lg} !important`,
                    fontWeight: `${fontWeightBold} !important`,
                    stroke: dark.main,
                    strokeWidth: "2px",
                    cursor: "pointer",
                  })}
                  onClick={handleClose}
                >
                  close
                </Icon>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox px={3}>
            <SoftBox pt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <SoftBox p={3}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pb={2}
                      >
                        <SoftTypography variant="h6">Estado de inscripciones</SoftTypography>
                      </SoftBox>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                          <SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproGreen")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Confirmaron la inscripcion: ${reporteConfirmados?.confirmados}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproYellow")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`No confirmaron la inscripción aún: ${reporteConfirmados?.noConfirmados}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproPink")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Se dieron de baja: ${reporteConfirmados?.baja}`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox pt={2}>
                              {loading ? (
                                <Skeleton width={100} />
                              ) : (
                                <SoftButton color="uproBlue" circular>
                                  {`Total ${
                                    reporteConfirmados?.confirmados +
                                    reporteConfirmados?.noConfirmados +
                                    reporteConfirmados?.baja
                                  }`}
                                </SoftButton>
                              )}
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <PieChart
                            id={new Date().getTime()}
                            title={"Test"}
                            chart={{
                              labels: ["Confirmados", "No confirmados", "Baja"],
                              datasets: {
                                backgroundColors: [
                                  getUproColor("uproGreen"),
                                  getUproColor("uproYellow"),
                                  getUproColor("uproPink"),
                                ],
                                data: [
                                  reporteConfirmados?.confirmados,
                                  reporteConfirmados?.noConfirmados,
                                  reporteConfirmados?.baja,
                                ],
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <SoftBox p={3}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        pb={2}
                      >
                        <SoftTypography variant="h6">
                          Tipo de inscripciones confirmadas
                        </SoftTypography>
                      </SoftBox>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                          <SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproGreen")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Curso de ingreso: ${
                                      reporteEstado?.totalInscripcionesEstado?.find(
                                        (estado) => estado.estado === "Inscripto"
                                      )?.cantidad || 0
                                    }`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={getUproColor("uproYellow")}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                {loading ? (
                                  <Skeleton width={150} />
                                ) : (
                                  <SoftTypography variant="button" fontWeight="medium">
                                    {`Exámen libre: ${
                                      reporteEstado?.totalInscripcionesEstado?.find(
                                        (estado) => estado.estado === "Libre"
                                      )?.cantidad || 0
                                    }`}
                                  </SoftTypography>
                                )}
                              </SoftBox>
                            </SoftBox>
                            <SoftBox pt={2}>
                              {loading ? (
                                <Skeleton width={100} />
                              ) : (
                                <SoftButton color="uproBlue" circular>
                                  {`Total ${reporteConfirmados?.confirmados}`}
                                </SoftButton>
                              )}
                            </SoftBox>
                          </SoftBox>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <PieChart
                            id={new Date().getTime()}
                            title={"Test"}
                            chart={{
                              labels: ["Curso de ingreso", "Exámen libre"],
                              datasets: {
                                backgroundColors: [
                                  getUproColor("uproGreen"),
                                  getUproColor("uproYellow"),
                                ],
                                data: [
                                  reporteEstado?.totalInscripcionesEstado?.find(
                                    (estado) => estado.estado === "Inscripto"
                                  )?.cantidad || 0,
                                  reporteEstado?.totalInscripcionesEstado?.find(
                                    (estado) => estado.estado === "Libre"
                                  )?.cantidad || 0,
                                ],
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalEstadoInscripciones.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
