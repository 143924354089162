/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import { makeNiceText } from "utils/formatters";

const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      let sedes = [];
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color:
                    item?.tipoUser === "Alumno"
                      ? getUproColor("uproYellow")
                      : item?.tipoUser === "Docente"
                      ? getUproColor("uproGreen")
                      : getUproColor("uproPink"),
                }}
                fontSize="lg"
              >
                {item?.tipoUser === "Alumno"
                  ? "school"
                  : item?.tipoUser === "Docente"
                  ? "supervisor_account"
                  : "manage_accounts"}
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item?.tipoUser} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color:
                      item?.tipoUser === "Alumno"
                        ? getUproColor("uproYellow")
                        : item?.tipoUser === "Docente"
                        ? getUproColor("uproGreen")
                        : getUproColor("uproPink"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipoUser === "Alumno"
                    ? "school"
                    : item?.tipoUser === "Docente"
                    ? "supervisor_account"
                    : "manage_accounts"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.apellido
                ? makeNiceText(item?.apellido + " " + item?.nombre, "nombre")
                : item?.username
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.apellido + " " + item?.nombre, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        estadoInscripcionCurso: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.estadoInscripcionCurso}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              <SoftBadge
                badgeContent={item?.estadoInscripcionCurso}
                variant="color"
                color={
                  item?.estadoInscripcionCurso === "Inscripto"
                    ? getUproColor("uproGreen")
                    : item?.estadoInscripcionCurso === "Aprobado"
                    ? getUproColor("uproGreen")
                    : item?.estadoInscripcionCurso === "Libre"
                    ? getUproColor("uproPink")
                    : item?.estadoInscripcionCurso === "Baja"
                    ? getUproColor("uproBlue")
                    : getUproColor("uproYellow")
                }
              />
            </SoftTypography>
          </SoftBox>
        ),
        sedeCurso: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.sedeCurso?.nombre || ""}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sedeCurso?.nombre || ""}
            </SoftTypography>
          </SoftBox>
        ),
        dni: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={formatMoneyPunto(item?.dni)}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {formatMoneyPunto(item?.dni)}
            </SoftTypography>
          </SoftBox>
        ),
        nroLegajo: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.nroLegajo}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.nroLegajo}
            </SoftTypography>
          </SoftBox>
        ),
        sede: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={sedes.map((sede, index) => {
              return sede.nombre + (index < sedes.length - 1 ? ", " : "");
            })}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {sedes.map((sede, index) => {
                return sede.nombre + (index < sedes.length - 1 ? ", " : "");
              })}
            </SoftTypography>
          </SoftBox>
        ),
        email: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {item?.email}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("uproGreen"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nroLegajo", desc: "Nº Legajo", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left", orderField: "nombreCompleto" },
  {
    name: "estadoInscripcionCurso",
    desc: "Estado inscripción curso",
    align: "left",
  },
  {
    name: "sedeCurso",
    desc: "Sede donde realiza el curso",
    align: "left",
    orderField: "idSedeCurso",
  },
  { name: "dni", desc: "DNI", align: "left" },
  { name: "email", desc: "Email", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
