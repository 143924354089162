// React
import { gql, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

// Components
import { Card, Collapse, Grid, Icon, Menu, MenuItem, Select, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ModalDelete from "components/Modals/Delete";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import { UserContext } from "context/user";
import dataCarrera from "layouts/carreras/data/dataCarreras";
import { getUproColor } from "utils/colors";
import ModalAulas from "./ModalAulas";
import ModalCarrera from "./ModalCarrera";
import ModalCursos from "./ModalCursos";
import ModalDocumentacionCarrera from "./ModalDocumentacion";
import ModalSedes from "./ModalSedes";
import ModalUnidadesAcademicas from "./ModalUnidadesAcademicas";
import ModalReporteComision from "./ModalReporteComisiones";

function Carreras() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [expandedFilter, setExpandedFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openUnidadesAcademicas, setOpenUnidadesAcademicas] = useState(false);
  const [openModalDocumentacion, setOpenModalDocumentacion] = useState(false);
  const [openModalReporteComisiones, setOpenModalReporteComisiones] = useState(false);
  const [openModalSedes, setOpenModalSedes] = useState(false);
  const [openModalAulas, setOpenModalAulas] = useState(false);
  const [openModalCursos, setOpenModalCursos] = useState(false);
  const [selectedCarrera, setSelectedCarrera] = useState(null);
  const [page, setPage] = useState(1);
  const [unidadesAcademicas, setUnidadesAcademicas] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [expandOptions, setExpandOptions] = useState(false);
  const [expandReportes, setExpandReportes] = useState(false);

  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataCarrera;
  const { menu } = useContext(MenuContext);

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedCarrera(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteCarrera] = useMutation(
    gql`
      mutation deleteCarrera($id: ID!) {
        deleteCarrera(id: $id) {
          id
        }
      }
    `
  );

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getCarreras($search: String, $page: Int, $order: Order, $filter: JSON) {
        paginationInfo {
          pages
          total
        }
        carreras(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          tipo
          nombre
          codigo
          descripcion
          sedes {
            id
            nombre
          }
          unidadAcademica {
            id
            nombre
            codigo
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        filter: { ...filter, tipo: "Carrera" },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      <MenuItem
        disabled={!verificarAcceso(17)}
        onClick={() => {
          setOpenModalAdd(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Editar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproGreen"),
              }}
            >
              edit
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        disabled={!verificarAcceso(19)}
        onClick={() => {
          setOpenModalDocumentacion(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Documentación</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproYellow"),
              }}
            >
              description
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        disabled={!verificarAcceso(18) || selectedCarrera?.tipoUser === "Administrador"}
        onClick={() => {
          setOpenModalDelete(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Eliminar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color:
                  selectedCarrera?.tipoUser === "Administrador"
                    ? getUproColor()
                    : getUproColor("uproPink"),
              }}
            >
              delete
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  const handleDeleteCarrera = async (id) => {
    try {
      await deleteCarrera({ variables: { id } });
      refetch();
      handleSnackbar("Usuario eliminado correctamente", "success");
    } catch (e) {
      handleSnackbar(e.message || "Error al eliminar usuario", "error");
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.carreras, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
      if (!unidadesAcademicas.length) {
        let tempUnidadesAcademicas = data.carreras.map((carrera) => carrera.unidadAcademica);
        tempUnidadesAcademicas = tempUnidadesAcademicas.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );
        setUnidadesAcademicas(tempUnidadesAcademicas);
      }
      if (!sedes.length) {
        let tempSedes = data.carreras.map((carrera) => carrera.sedes);
        tempSedes = tempSedes.flat();
        tempSedes = tempSedes.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        setSedes(tempSedes);
      }
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Carreras</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Agregar nueva carrera" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          iconOnly
                          onClick={() => setOpenModalAdd(true)}
                          disabled={!verificarAcceso(16)}
                        >
                          <Icon>add</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                    >
                      <Tooltip
                        title="Administrar cursos, unidades académicas, sedes y aulas "
                        placement="top"
                      >
                        <SoftButton
                          color="uproBlue"
                          circular
                          onClick={(event) => {
                            setExpandOptions(true);
                            menu({
                              open: event.currentTarget,
                              align: "right",
                              options: [
                                {
                                  name: "Cursos",
                                  icon: { icon: "class", color: getUproColor("uproGreen") },
                                  onClick: () => setOpenModalCursos(true),
                                  disabled: !verificarAcceso(15),
                                },
                                {
                                  name: "Unidades académicas",
                                  icon: {
                                    icon: "account_balance",
                                    color: getUproColor("uproYellow"),
                                  },
                                  onClick: () => setOpenUnidadesAcademicas(true),
                                  disabled: !verificarAcceso(14),
                                },
                                {
                                  name: "Sedes",
                                  icon: { icon: "apartment", color: getUproColor("uproBlue") },
                                  onClick: () => setOpenModalSedes(true),
                                  disabled: !verificarAcceso(13),
                                },
                                {
                                  name: "Aulas",
                                  icon: { icon: "chair_alt", color: getUproColor("uproPink") },
                                  onClick: () => setOpenModalAulas(true),
                                  disabled: !verificarAcceso(23),
                                },
                              ],
                              onClose: () => {
                                setExpandOptions(false);
                              },
                            });
                          }}
                        >
                          <Icon>dashboard</Icon>
                          &nbsp;Administrar&nbsp;
                          <Icon
                            sx={{
                              rotate: expandOptions ? "180deg" : "0deg",
                              transition: "all 0.5s",
                            }}
                          >
                            expand_more
                          </Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                    >
                      <Tooltip title="Ver los distintos reportes disponibles" placement="top">
                        <SoftButton
                          color="uproGreen"
                          circular
                          onClick={(event) => {
                            setExpandReportes(true);
                            menu({
                              open: event.currentTarget,
                              align: "right",
                              options: [
                                {
                                  name: "Reporte de comisiones",
                                  icon: { icon: "bar_chart", color: getUproColor("uproPink") },
                                  onClick: () => setOpenModalReporteComisiones(true),
                                },
                              ],
                              handleClose: () => {},
                              onClose: () => {
                                setExpandReportes(false);
                              },
                            });
                          }}
                        >
                          <Icon>bar_chart</Icon>
                          &nbsp;Reportes&nbsp;
                          <Icon sx={{
                            rotate: expandReportes ? "180deg" : "0deg",
                            transition: "all 0.5s",
                          }}>expand_more</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                    >
                      <Tooltip title="Agregar nueva carrera" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          onClick={() => setOpenModalAdd(true)}
                          disabled={!verificarAcceso(16)}
                        >
                          <Icon>add</Icon>
                          &nbsp;Agregar
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Filtros" placement="top">
                        <SoftButton
                          color="uproYellow"
                          circular
                          iconOnly
                          onClick={() => setExpandedFilter(!expandedFilter)}
                        >
                          <Icon>{expandedFilter ? "filter_alt_off" : "filter_alt"}</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <Collapse in={expandedFilter}>
                  <SoftBox display="flex" alignItems="center" justifyContent="end" mt={2}>
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <Select
                        value={filter.idUnidadAcademica || -1}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            idUnidadAcademica: e.target.value === -1 ? undefined : e.target.value,
                          })
                        }
                      >
                        <MenuItem value={-1}>Todas las unidades académicas</MenuItem>
                        {unidadesAcademicas.map((unidad) => (
                          <MenuItem key={unidad.id} value={unidad.id}>
                            {unidad.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </SoftBox>
                    <SoftBox
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <Select
                        value={filter.idSede || -1}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            idSede: e.target.value === -1 ? undefined : e.target.value,
                          })
                        }
                      >
                        <MenuItem value={-1}>Todas las sedes</MenuItem>
                        {sedes.map((sede) => (
                          <MenuItem key={sede.id} value={sede.id}>
                            {sede.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </SoftBox>
                  </SoftBox>
                </Collapse>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : rows?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.carreras.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
          {renderMenu}
          <ModalCarrera
            open={openModalAdd}
            handleClose={() => {
              setOpenModalAdd(false);
              setSelectedCarrera();
            }}
            refetch={refetch}
            idCarrera={selectedCarrera?.id}
            tipo="Carrera"
          />
          <ModalDelete
            open={openModalDelete}
            handleClose={() => {
              setOpenModalDelete(false);
              setSelectedCarrera();
            }}
            nombre={`la carrera ${selectedCarrera?.nombre}`}
            functionDelete={() => {
              handleDeleteCarrera(selectedCarrera.id);
              setOpenModalDelete(false);
              refetch();
            }}
          />
          <ModalUnidadesAcademicas
            open={openUnidadesAcademicas}
            handleClose={() => setOpenUnidadesAcademicas(false)}
            refetch={refetch}
          />
          <ModalDocumentacionCarrera
            open={openModalDocumentacion}
            handleClose={() => setOpenModalDocumentacion(false)}
            idCarrera={selectedCarrera?.id}
            readOnly={false}
          />
          <ModalSedes
            open={openModalSedes}
            handleClose={() => setOpenModalSedes(false)}
            refetch={refetch}
          />
          <ModalAulas
            open={openModalAulas}
            handleClose={() => setOpenModalAulas(false)}
            refetch={refetch}
          />
          <ModalReporteComision
            open={openModalReporteComisiones}
            handleClose={() => setOpenModalReporteComisiones(false)}
          />
          <ModalCursos open={openModalCursos} handleClose={() => setOpenModalCursos(false)} />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Carreras;
