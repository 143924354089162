/* eslint-disable react/prop-types */

import { Icon, Menu, MenuItem } from "@mui/material";
import SoftBox from "components/CheckBox";
import SoftTypography from "components/SoftTypography";
import { createContext, useState } from "react";

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [menuData, setMenuData] = useState({
    open: null,
    align: "left",
    options: [],
    handleClose: () => {},
  });

  const menu = ({ open, align, options, onClose }) => {
    setMenuData({
      open,
      align,
      options,
      handleClose: () => {
        setMenuData((prev) => ({ ...prev, open: null }));
        onClose && onClose();
      },
    });
  };

  const renderMenu = (
    <Menu
      anchorEl={menuData.open}
      open={Boolean(menuData.open)}
      onClose={menuData.handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: menuData.align,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: menuData.align,
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: {
            xs: "90vh",
            sm: "300px",
          },
          width: {
            xs: "90vh",
            sm: "auto",
          },
          overflowY: "auto",
          overflowX: "hidden",
          mt: 4,
        },
      }}
    >
      {menuData.options
        .filter((option) => !option?.hidden)
        .map((option, index) => (
          <MenuItem
            onClick={() => {
              option?.onClick();
              menuData.handleClose();
            }}
            key={index}
            disabled={option?.disabled}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
              p={{
                xs: 1,
                sm: 0,
              }}
            >
              <SoftBox mr={2}>
                <SoftTypography
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                  variant="p"
                >
                  {option?.name || "Sin nombre"}
                </SoftTypography>
                <SoftTypography
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                    },
                  }}
                  variant="h6"
                >
                  {option?.name || "Sin nombre"}
                </SoftTypography>
              </SoftBox>
              {option?.icon?.icon && (
                <SoftBox
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  {typeof option?.icon?.color === "func" ||
                    (typeof option?.icon?.color === "string" &&
                    option?.icon?.color.includes("#") ? (
                      <Icon
                        fontSize="small"
                        sx={{
                          verticalAlign: "middle",
                          color: option?.icon?.color,
                        }}
                      >
                        {option?.icon?.icon || "warning"}
                      </Icon>
                    ) : (
                      <Icon
                        fontSize="small"
                        color={option?.icon?.color}
                        sx={{
                          verticalAlign: "middle",
                        }}
                      >
                        {option?.icon?.icon || "warning"}
                      </Icon>
                    ))}
                </SoftBox>
              )}

              {option?.icon?.icon && (
                <SoftBox
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                    },
                  }}
                >
                  {typeof option?.icon?.color === "func" ||
                    (typeof option?.icon?.color === "string" &&
                    option?.icon?.color.includes("#") ? (
                      <Icon
                        fontSize="medium"
                        sx={{
                          verticalAlign: "middle",
                          color: option?.icon?.color,
                        }}
                      >
                        {option?.icon?.icon || "warning"}
                      </Icon>
                    ) : (
                      <Icon
                        fontSize="medium"
                        color={option?.icon?.color}
                        sx={{
                          verticalAlign: "middle",
                        }}
                      >
                        {option?.icon?.icon || "warning"}
                      </Icon>
                    ))}
                </SoftBox>
              )}
            </SoftBox>
          </MenuItem>
        ))}
      {menuData.options?.filter((option) => !option?.hidden).length === 0 && (
        <MenuItem disabled={true}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2}>
              <SoftTypography
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                }}
                variant="p"
              >
                No hay opciones disponibles
              </SoftTypography>
              <SoftTypography
                sx={{
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
                variant="h6"
              >
                No hay opciones disponibles
              </SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                },
              }}
            >
              <Icon
                fontSize="small"
                color="secondary"
                sx={{
                  verticalAlign: "middle",
                }}
              >
                search_off
              </Icon>
            </SoftBox>
            <SoftBox sx={{ display: { xs: "block", sm: "none" } }}>
              <Icon
                fontSize="medium"
                color="secondary"
                sx={{
                  verticalAlign: "middle",
                }}
              >
                search_off
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <MenuContext.Provider value={{ menu }}>
      {renderMenu}
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };
