import { gql, useQuery } from "@apollo/client";
import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import logo from "assets/images/logo.png";
import PageLayout from "components/LayoutContainers/PageLayout";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useParams } from "react-router-dom";
import { API_URL } from "services/config";

function Certificado() {
  const { id } = useParams();

  const { data, loading, error } = useQuery(
    gql`
      query getCeritificado($id: ID!) {
        certificadoPublic(id: $id) {
          id
          tipo
          url
        }
      }
    `,
    {
      variables: { id },
    }
  );

  return (
    <PageLayout>
      <SoftBox display="flex" justifyContent="center" alignItems="center" height="100vh" p={3}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={4}>
            <Card>
              <SoftBox p={2}>
                <SoftBox display="flex" justifyContent="center" alignItems="center">
                  <SoftBox component="img" src={logo} alt="UPrO Logo" width="150px" p={3} />
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={2}
                  textAlign="center"
                >
                  <SoftTypography variant="h6">
                    {loading
                      ? "Cargando certificado..."
                      : data
                      ? `${data.certificadoPublic.tipo}`
                      : error
                      ? error.message
                      : "Certificado no encontrado"}
                  </SoftTypography>
                </SoftBox>
                {loading ? (
                  <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    p={2}
                  >
                    <CircularProgress size={30} color="primary" />
                  </SoftBox>
                ) : data ? (
                  <SoftBox display="flex" justifyContent="center" alignItems="center" p={2}>
                    <SoftButton
                      href={`${API_URL}/${data.certificadoPublic.url}`}
                      target="_blank"
                      color="primary"
                      circular
                    >
                      Descargar Certificado&nbsp;
                      <Icon>download</Icon>
                    </SoftButton>
                  </SoftBox>
                ) : (
                  <SoftBox display="flex" justifyContent="center" alignItems="center" p={2}>
                    <SoftButton color="primary" circular href="/login">
                      <Icon>arrow_back</Icon>&nbsp; Volver a inicio
                    </SoftButton>
                  </SoftBox>
                )}
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </PageLayout>
  );
}

export default Certificado;
