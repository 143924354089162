import PropTypes from "prop-types";
import { useContext, useState } from "react";

// @mui material components
import { Card, Divider, Icon, Skeleton, Tooltip } from "@mui/material/";
import Button from "@mui/material/Button";

// Kaizen Dashboard components
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { API_URL } from "services/config";

import { MessageManager } from "context";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import dayjs from "dayjs";
import SinDatos from "components/Carteles/SinDatos";

const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

function EventosCard({ fecha, eventos, usuario, carrera, loading, handleClick }) {
  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {fecha ? (
        <SoftBox p={2} lineHeight={1.25}>
          <SoftTypography variant="h6" fontWeight="medium">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={200} sx={{ fontSize: "1rem" }} />
              </SoftBox>
            ) : (
              `${daysOfWeek[dayjs(fecha).day()]}, ${dayjs(fecha).date()} de ${
                months[dayjs(fecha).month()]
              } de ${dayjs(fecha).year()}`
            )}
          </SoftTypography>
          <SoftTypography variant="h7" color="text" fontWeight="regular">
            {loading ? (
              <SoftBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Skeleton variant="text" width={100} sx={{ fontSize: "0.5rem" }} />
              </SoftBox>
            ) : eventos.length > 0 ? (
              "Tienes eventos programados para este día"
            ) : (
              "No tienes eventos programados para este día"
            )}
          </SoftTypography>
          <Divider />
          <SoftBox
            sx={{
              height: "100%",
              maxHeight: "calc(60vh)",
              overflowY: "auto",
            }}
          >
            {loading ? (
              <SoftBox>
                <Skeleton variant="text" width={"100%"} />
                <Skeleton variant="text" width={"100%"} />
              </SoftBox>
            ) : eventos.length > 0 ? (
              eventos.map((evento, index) => (
                <SoftBox
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={index === 0 ? 0 : 1}
                >
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox mr={1} display="flex" alignItems="center">
                      <Tooltip title={evento.type} placement="top">
                        <Icon
                          sx={{
                            color: evento.color,
                            cursor: "pointer",
                          }}
                        >
                          {evento.icon}
                        </Icon>
                      </Tooltip>
                    </SoftBox>
                    <SoftTypography variant="h7" color="text" fontWeight="regular">
                      {evento.title}
                    </SoftTypography>
                  </SoftBox>
                    <SoftBox px={2}>
                  <Tooltip title={evento.tooltip} placement="top">
                      <SoftButton
                        color={evento.btnColor || "uproGreen"}
                        circular
                        iconOnly
                        size="small"
                        onClick={evento.action}
                        disabled={evento.disabled}
                      >
                        <Icon>{evento.btnIcon || "arrow_forward"}</Icon>
                      </SoftButton>
                  </Tooltip>
                    </SoftBox>
                </SoftBox>
              ))
            ) : (
              <SinDatos />
            )}
          </SoftBox>
        </SoftBox>
      ) : (
        <SinDatos />
      )}
    </Card>
  );
}

// Setting default values for the props of EventosCard
EventosCard.defaultProps = {
  color: "primary",
  eventos: [],
  usuario: {
    sucursal: {},
    puesto: {},
    user: {},
    localidad: { provincia: {} },
    sucursales: [{}],
  },
  loading: true,
  handleClick: () => {},
};

// Typechecking props for the EventosCard
EventosCard.propTypes = {
  fecha: PropTypes.string,
  eventos: PropTypes.array,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "terraPrimary",
    "terraSecondary",
  ]),
  usuario: PropTypes.object.isRequired,
  carrera: PropTypes.object,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default EventosCard;
