// React
import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "30%", xxl: "25%" },
  overflowY: "auto",
};

export default function ModalExito({ open, handleClose }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftTypography variant="h6">Exámen guardado con éxito</SoftTypography>
                    <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                      <SoftBox display="flex" alignItems="center">
                        <Tooltip title="Cerrar" placement="top">
                          <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  <SoftBox>
                    <Icon
                      sx={{
                        color: getUproColor("uproGreen"),
                      }}
                      fontSize="large"
                    >
                      check_circle
                    </Icon>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography variant="h6">
                      ¡El examen ha sido guardado con éxito!
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox >
                    <SoftTypography variant="caption" component="p" color="text">
                      Puedes volver a carreras o continuar editando el exámen
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <Link to="/carreras">
                      <SoftButton color="primary" circular fullWidth>
                        Volver a carreras &nbsp;
                        <Icon>arrow_forward</Icon>
                      </SoftButton>
                    </Link>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftButton color="uproGreen" onClick={handleClose} circular fullWidth>
                      Seguir editando &nbsp;
                      <Icon>edit</Icon>
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </Card>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalExito.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
