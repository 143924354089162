/* eslint-disable react/prop-types */
import { createContext, useContext } from "react";
import { ApolloProvider } from "@apollo/client";
import { client, clientComedor } from "services/config";

const ApolloClientContext = createContext(client);

export const ApolloClientProvider = ({ children }) => {
  return (
    <ApolloProvider client={client}> 
      <ApolloClientContext.Provider value={{ client, clientComedor }}>
        {children}
      </ApolloClientContext.Provider>
    </ApolloProvider>
  );
};

export const useApolloClient = (client = "client") => {
  const clients = useContext(ApolloClientContext);
  return clients[client];
};