import { Card, CircularProgress, Collapse, Grid, Icon, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import { gql, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import { useContext, useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import ProfileCard from "./components/ProfileCard";
import ModalNuevoPedido from "./components/ModalNuevoPedido";
import { useApolloClient } from "context/apolloClientContext";
import DataTable from "components/Tables/DataTable";
import dataPedidos from "./data/dataPedidos";
import SoftInput from "components/SoftInput";
import CustomPagination from "components/Pagination";
import dayjs from "dayjs";

function Comedor() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "id",
    orden: "DESC",
  });
  const { menu } = useContext(MenuContext);
  const [expanded, setExpanded] = useState(["Pedidos"]);
  const [openModalNuevoPedido, setOpenModalNuevoPedido] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const client = useApolloClient("clientComedor");

  const { columns, getRows } = dataPedidos;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const { loading, data, refetch } = useQuery(
    gql`
      query misPedidos($search: String, $page: Int, $order: Order, $app: Apps) {
        paginationInfo {
          pages
          total
        }
        misPedidos(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          app: $app
        ) {
          id
          estado
          total
          createdAt
          updatedAt
          fechaPedido
          cliente {
            nombre
            apellido
          }
          detalles {
            id
            numeroComanda
            cantidad
            precio
          }
          mesa {
            numero
          }
          venta {
            id
            tipoFactura
            idAnulada
          }
          mozo {
            nombre
            apellido
          }
        }
      }
    `,
    {
      client,
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        app: "Alumnos",
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const { data: usuario } = useQuery(gql`
    query getUser {
      currentUser {
        id
        tipoUser
        username
        nombre
        apellido
        email
        dni
      }
    }
  `);

  useEffect(() => {
    if (data) {
      setRows(getRows(data.misPedidos, (event, item) => {
        let opciones = [
          {
            name: "Imprimir factura",
            icon: { icon: "print", color: getUproColor("uproGreen") },
            disabled: !item.venta,
            onClick: () => {
              getVenta({ variables: { id: item.venta.id } }).then((res) => {
                printFactura(res.data.venta, user.empresa);
              });
            },
          },
        ];

        if (item?.estado === "RESERVADO") {
          opciones.push({
            name: "Entregar pedido",
            icon: { icon: "done", color: getUproColor("uproGreen") },
            disabled: !dayjs(item?.fechaPedido).isSame(dayjs().startOf("day")),
            onClick: () => {},
          });
        }
        if (item?.venta?.idAnulada) {
          opciones.push({
            name: "Imprimir nota de crédito",
            icon: { icon: "print", color: getUproColor("uproBlue") },
            disabled: !item.venta.idAnulada,
            onClick: () => {
              getVenta({ variables: { id: item.venta.idAnulada } }).then((res) => {
                printFactura(res.data.venta, user.empresa);
              });
            },
          });
        } else {
          opciones.push({
            name: "Anular",
            icon: { icon: "close", color: getUproColor("uproPink") },
            disabled: !item.venta || item.venta.idAnulada || loadingDelete,
            onClick: () => {
              confirmar({
                icon: "warning",
                title: "Anular",
                message:
                  "¿Está seguro que desea anular la factura?. No podrá deshacer esta acción.",
                func: () => {
                  let nroFacturaAnulada =
                    item.venta.tipoFactura == "V"
                      ? Number(item.venta.id)
                      : Number(item.venta.numeroDeFacturaAfip.slice(5));
                  deleteVenta({
                    variables: {
                      input: {
                        cliente: item.venta.cliente.nombre + " " + item.venta.cliente.apellido,
                        concepto: item.venta.concepto,
                        cuitEmpresa: "20249903877",
                        descuento: item.venta.descuento,
                        fecha: dayjs().format("YYYY-MM-DD"),
                        idPedido: item.venta.idPedido,
                        imp_iva21: item.venta.imp_iva21,
                        nro_doc: item.venta.nro_doc,
                        idAnulada: Number(item.venta.id),
                        nroAnulado: nroFacturaAnulada,
                        pto_vta: item.venta.pto_vta,
                        recargo: item.venta.recargo,
                        subtotal21: item.venta.subtotal21,
                        tipo_cbte: formatTipoCbte[item.venta.tipoFactura],
                        tipo_doc: item.venta.tipo_doc,
                        tipoFactura: notasDeCredito[item.venta.tipoFactura],
                        total: item.venta.total,
                      },
                      id: item.venta.id,
                    },
                  })
                    .then(() => {
                      refetch();
                      handleSnackbar("Factura anulada correctamente", "success");
                    })
                    .catch((err) => {
                      handleSnackbar(err.message || "Error al anular la factura", "error");
                    });
                },
              });
            },
          });
        }
        menu({
          open: event.currentTarget,
          align: "right",
          options: opciones,
          handleClose: () => {},
        });
      }));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <ProfileCard
                        usuario={usuario?.currentUser}
                        loading={loading}
                        handleClick={() => setTab(2)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Card>
                        <SoftBox
                          p={{
                            xs: 0,
                            sm: 2,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Card
                                sx={{
                                  border: "1px solid rgba(0, 0, 0, 0.12)",
                                }}
                              >
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  px={2}
                                  py={1}
                                  pt={{
                                    xs: 2,
                                    sm: 1,
                                  }}
                                  gap={2}
                                  flexDirection={{
                                    xs: "column",
                                    sm: "row",
                                  }}
                                  sx={{
                                    borderBottom: !expanded.includes("Pedidos")
                                      ? "none"
                                      : "1px solid rgba(0, 0, 0, 0.12)",
                                    cursor: "pointer",
                                    "&:hover": {
                                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    },
                                  }}
                                >
                                  <SoftBox display="flex" alignItems="center">
                                    <SoftBox mr={1} display="flex" alignItems="center">
                                      <Icon color="primary">receipt</Icon>
                                    </SoftBox>
                                    <SoftTypography variant="h6" fontWeight="bold">
                                      Pedidos realizados
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    gap={2}
                                  >
                                    <SoftBox
                                      sx={{
                                        width: {
                                          xs: "100%",
                                          sm: "auto",
                                        },
                                      }}
                                    >
                                      <SoftInput
                                        placeholder="Escribe aquí..."
                                        icon={{ component: "search", direction: "left" }}
                                        onChange={handleSearch}
                                      />
                                    </SoftBox>
                                    <SoftBox
                                      display={{
                                        xs: "none",
                                        sm: "flex",
                                      }}
                                    >
                                      <Tooltip title="Nuevo pedido" placement="top">
                                        <SoftButton
                                          color="uproGreen"
                                          circular
                                          onClick={() => setOpenModalNuevoPedido(true)}
                                        >
                                          <Icon>add_shopping_cart</Icon>
                                          &nbsp; Nuevo pedido
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                    <SoftBox
                                      display={{
                                        xs: "flex",
                                        sm: "none",
                                      }}
                                    >
                                      <Tooltip title="Nuevo pedido" placement="top">
                                        <SoftButton
                                          color="uproGreen"
                                          circular
                                          iconOnly
                                          onClick={() => setOpenModalNuevoPedido(true)}
                                        >
                                          <Icon>add_shopping_cart</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                                <Collapse in={expanded.includes("Pedidos")}>
                                  <SoftBox
                                    sx={{
                                      "& .MuiTableRow-root:not(:last-child)": {
                                        "& td": {
                                          borderBottom: ({
                                            borders: { borderWidth, borderColor },
                                          }) => `${borderWidth[1]} solid ${borderColor}`,
                                        },
                                      },
                                    }}
                                  >
                                    {loading ? (
                                      <Loading />
                                    ) : rows?.length > 0 ? (
                                      <DataTable
                                        columns={columns}
                                        rows={rows}
                                        order={orderBy}
                                        setOrder={handleOrderBy}
                                      />
                                    ) : (
                                      <SinDatos />
                                    )}
                                  </SoftBox>
                                  <CustomPagination
                                    loading={loading}
                                    length={data?.misPedidos.length}
                                    total={pagesInfo?.total}
                                    pages={pagesInfo?.pages}
                                    page={page}
                                    setPage={setPage}
                                    noCard
                                  />
                                </Collapse>
                              </Card>
                            </Grid>
                          </Grid>
                        </SoftBox>
                      </Card>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
          </Card>
          <ModalNuevoPedido
            open={openModalNuevoPedido}
            handleClose={() => setOpenModalNuevoPedido(false)}
            refetch={refetch}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Comedor;
