import { gql, useLazyQuery } from "@apollo/client";
import { Card, CircularProgress, Collapse, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
};

export default function ModalAsistencias({ open, handleClose, comision, alumno }) {
  const [limit, setLimit] = useState(10);
  const [getAsistencias, { data: asistencias, loading }] = useLazyQuery(gql`
    query asistenciasAlumno($idAlumno: ID!, $idComision: ID!, $limit: Int) {
      asistenciasAlumno(idAlumno: $idAlumno, idComision: $idComision, limit: $limit) {
        id
        fecha
        estado
      }
    }
  `);

  useEffect(() => {
    if (open) {
      getAsistencias({
        variables: {
          idAlumno: alumno.id,
          idComision: comision.id,
          limit,
        },
      });
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Boolean(open)}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3} pb={0}>
              <SoftBox>
                <SoftTypography variant="h6">Historial de asistencias</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      p={2}
                      sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox mr={1} display="flex" alignItems="center">
                          <Icon color="primary">assignment_turned_in</Icon>
                        </SoftBox>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {`Asistencias a ${makeNiceText(
                            `${comision.codigo} - ${comision.nombre}`,
                            "nombre"
                          )}`}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <Collapse in={!loading}>
                      {asistencias?.asistenciasAlumno?.length > 0 ? (
                        asistencias?.asistenciasAlumno?.map((item, index) => (
                          <SoftBox key={item.id}>
                            <SoftBox
                              px={2}
                              py={1}
                              sx={{
                                borderBottom:
                                  asistencias?.asistenciasAlumno?.length - 1 === index
                                    ? "none"
                                    : "1px solid rgba(0, 0, 0, 0.12)",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                                },
                              }}
                            >
                              <SoftBox display="flex" justifyContent="space-between">
                                <SoftBox
                                  display="flex"
                                  alignItems="center"
                                  pl={{
                                    xs: 0,
                                    sm: 2,
                                  }}
                                >
                                  <SoftBox mr={1}>
                                    <Icon
                                      sx={{
                                        color: getUproColor("uproPink"),
                                      }}
                                    >
                                      subdirectory_arrow_right
                                    </Icon>
                                  </SoftBox>
                                  <SoftBox
                                    mr={1}
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "block",
                                      },
                                    }}
                                  >
                                    <Tooltip title={item.estado} placement="top">
                                      <Icon
                                        sx={{
                                          color:
                                            item.estado === "Presente"
                                              ? getUproColor("uproGreen")
                                              : item.estado === "Justificado"
                                              ? getUproColor("uproYellow")
                                              : getUproColor("uproPink"),
                                        }}
                                      >
                                        {item.estado === "Presente"
                                          ? "done"
                                          : item.estado === "Justificado"
                                          ? "edit_note"
                                          : "clear"}
                                      </Icon>
                                    </Tooltip>
                                  </SoftBox>
                                  <SoftTypography variant="h6">
                                    {dayjs(item.fecha).format("DD/MM/YYYY")}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox justifyContent="flex-end" alignItems="center">
                                  <SoftBadge
                                    color={
                                      item.estado === "Presente"
                                        ? "uproGreen"
                                        : item.estado === "Justificado"
                                        ? "uproYellow"
                                        : "primary"
                                    }
                                    badgeContent={item.estado}
                                    size="md"
                                  />
                                </SoftBox>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                        ))
                      ) : (
                        <Grid container spacing={2} p={2}>
                          <SinDatos />
                        </Grid>
                      )}
                    </Collapse>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <SoftButton
                    circular
                    disabled={loading || asistencias?.asistenciasAlumno?.length < limit}
                    onClick={() => {
                      setLimit(limit + 10);
                    }}
                    color="primary"
                    fullWidth
                  >
                    {loading ? <CircularProgress size={15} color="inherit" /> : <Icon>add</Icon>}
                    &nbsp; Cargar más
                  </SoftButton>
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAsistencias.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  comision: PropTypes.object,
  alumno: PropTypes.object,
};
