// @mui material components
import { Card, Collapse, Grid, Icon, Slide, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";

// Kaizen Dashboard components
import { gql, useQuery } from "@apollo/client";
import VerticalBarChart from "components/Charts/BarCharts/VerticalBarChart";
import DoughnutChart from "components/Charts/DoughnutCharts/CustomDoughnutChart";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import dayjs from "dayjs";
import { getUproColor, uproColors } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import * as XLSX from "xlsx";

function ReporteCarreras({ tipo, sedes, tipoCarrera }) {
  const [date, setDate] = useState({
    nombre: "Último año",
    fechaInicio: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  });
  const [selectedSede, setSelectedSede] = useState();
  const [expandSelector, setExpandSelector] = useState(false);
  const [expandSedes, setExpandSedes] = useState(false);
  const [expandCarreras, setExpandCarreras] = useState(false);
  const { data: reportes } = useQuery(
    gql`
      query getReportes(
        $fechaInicio: String
        $fechaFin: String
        $tipo: [String]
        $idSede: ID
        $tipoCarrera: String
      ) {
        reporteCarreras(
          fechaInicio: $fechaInicio
          fechaFin: $fechaFin
          tipo: $tipo
          idSede: $idSede
          tipoCarrera: $tipoCarrera
        ) {
          totalInscripciones
          tipoInscripciones
          carrerasMasInscriptas {
            cantidad
            carrera {
              id
              nombre
            }
          }
          totalInscripcionesCarrreras {
            cantidad
            carrera {
              id
              nombre
            }
          }
          inscripcionesPorUnidadAcademica {
            cantidad
            unidadAcademica {
              id
              nombre
            }
          }
          totalPorSede {
            cantidad
            sede {
              id
              nombre
            }
          }
        }
      }
    `,
    {
      variables: {
        fechaInicio: date.fechaInicio,
        fechaFin: date.fechaFin,
        tipo: tipo.split(","),
        idSede: selectedSede?.id || null,
        tipoCarrera,
      },
    }
  );
  const { menu } = useContext(MenuContext);

  return (
    <Card>
      <SoftBox p={3}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
          <SoftTypography variant="h6">{`Reporte de ${
            tipo === "Preinscripto" ? "preinscripciones" : "inscripciones"
          } por ${tipoCarrera.toLowerCase()}`}</SoftTypography>
          <Tooltip
            title={`Desde ${dayjs(date.fechaInicio).format("DD/MM/YYYY")} hasta ${dayjs(
              date.fechaFin
            ).format("DD/MM/YYYY")}`}
            placement="top"
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={0.5}>
              <SoftBox display="flex" justifyContent="start" alignItems="center">
                <SoftBox mr={2}>
                  <SoftButton
                    color="uproGreen"
                    circular
                    onClick={(event) => {
                      setExpandSedes(!expandSedes);
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        onClose: () => {
                          setExpandSedes(false);
                        },
                        options: [
                          {
                            name: "Todas las sedes",
                            icon: {
                              icon: !selectedSede ? "location_on" : "location_city",
                              color: !selectedSede
                                ? getUproColor("uproPink")
                                : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setSelectedSede(null);
                            },
                          },
                          ...sedes.map((sede) => ({
                            name: sede.nombre,
                            icon: {
                              icon: selectedSede?.id === sede.id ? "location_on" : "location_city",
                              color:
                                selectedSede?.id === sede.id
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setSelectedSede(sede);
                            },
                          })),
                        ],
                        handleClose: () => {
                          setExpandSelector(false);
                        },
                      });
                    }}
                  >
                    <Icon fontSize="small">apartment</Icon>
                    &nbsp; {selectedSede?.nombre || "Todas las sedes"} &nbsp;
                    <Icon
                      sx={{
                        rotate: expandSedes ? "180deg" : "0deg",
                        transition: "all 0.5s",
                      }}
                    >
                      expand_more
                    </Icon>
                  </SoftButton>
                </SoftBox>
                <SoftBox>
                  <SoftButton
                    color="primary"
                    circular
                    onClick={(event) => {
                      setExpandSelector(!expandSelector);
                      menu({
                        open: event.currentTarget,
                        align: "right",
                        onClose: () => {
                          setExpandSelector(false);
                        },
                        options: [
                          {
                            name: "Hoy",
                            icon: {
                              icon: date.nombre === "Hoy" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Hoy"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Hoy",
                                fechaInicio: dayjs().startOf("day").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Última semana",
                            icon: {
                              icon:
                                date.nombre === "Última semana" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Última semana"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Última semana",
                                fechaInicio: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Último mes",
                            icon: {
                              icon: date.nombre === "Último mes" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Último mes"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Último mes",
                                fechaInicio: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Últimos 3 meses",
                            icon: {
                              icon:
                                date.nombre === "Últimos 3 meses"
                                  ? "event_available"
                                  : "date_range",
                              color:
                                date.nombre === "Últimos 3 meses"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Últimos 3 meses",
                                fechaInicio: dayjs().subtract(3, "month").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Últimos 6 meses",
                            icon: {
                              icon:
                                date.nombre === "Últimos 6 meses"
                                  ? "event_available"
                                  : "date_range",
                              color:
                                date.nombre === "Últimos 6 meses"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Últimos 6 meses",
                                fechaInicio: dayjs().subtract(6, "month").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Último año",
                            icon: {
                              icon: date.nombre === "Último año" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Último año"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Último año",
                                fechaInicio: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                          {
                            name: "Histórico",
                            icon: {
                              icon: date.nombre === "Histórico" ? "event_available" : "date_range",
                              color:
                                date.nombre === "Histórico"
                                  ? getUproColor("uproPink")
                                  : getUproColor("uproGreen"),
                            },
                            onClick: () => {
                              setDate({
                                nombre: "Histórico",
                                fechaInicio: "2021-01-01",
                                fechaFin: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                              });
                            },
                          },
                        ],
                        handleClose: () => {
                          setExpandSelector(false);
                        },
                      });
                    }}
                  >
                    <Icon fontSize="small">calendar_month</Icon>
                    &nbsp; {date.nombre} &nbsp;
                    <Icon
                      sx={{
                        rotate: expandSelector ? "180deg" : "0deg",
                        transition: "all 0.5s",
                      }}
                    >
                      expand_more
                    </Icon>
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Tooltip>
        </SoftBox>
        <Grid container spacing={2}>
          {tipoCarrera === "Curso de Ingreso" ? (
            <Grid item xs={12} sm={4}>
              <Card>
                <SoftBox p={3}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                    <SoftTypography variant="h6">Tipo de inscripción al curso de ingreso</SoftTypography>
                  </SoftBox>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <DoughnutChart
                        id={4}
                        title="Inclusión Laboral"
                        chart={{
                          labels: reportes?.reporteCarreras?.tipoInscripciones?.map(
                            (item) => item.tipo
                          ),
                          datasets: {
                            backgroundColors: [getUproColor("uproGreen"), getUproColor("uproPink")],
                            data: reportes?.reporteCarreras?.tipoInscripciones?.map(
                              (item) => item.cantidad
                            ),
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SoftBox>
                        {reportes?.reporteCarreras?.tipoInscripciones?.map((item, index) => (
                          <SoftBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            key={index}
                          >
                            <SoftBox display="flex" alignItems="center">
                              <SoftBox mr={2}>
                                <SoftButton
                                  size="small"
                                  variant="color"
                                  color={index === 0 ? getUproColor("uproGreen") : getUproColor("uproPink")}
                                  circular
                                  iconOnly
                                />
                              </SoftBox>
                              <SoftTypography variant="button" fontWeight="medium">
                                {`${item.tipo} (${item.cantidad})`}
                              </SoftTypography>
                            </SoftBox>
                          </SoftBox>
                        ))}
                        <SoftBox pt={2}>
                          <SoftButton variant="gradient" color="uproGreen" circular>
                            {`Total ${formatMoneyPunto(
                              reportes?.reporteCarreras?.totalInscripciones || 0
                            )}`}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={4}>
              <Card
                sx={{
                  height: "100%",
                }}
              >
                <SoftBox
                  p={3}
                  sx={{
                    height: "100%",
                  }}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      pb={2}
                    >
                      <SoftTypography variant="h6">{`${
                        tipo === "Preinscripto" ? "Preinscriptos" : "Inscriptos"
                      } por ${tipoCarrera.toLowerCase()}`}</SoftTypography>
                      <SoftButton
                        size="small"
                        variant="gradient"
                        color="dark"
                        circular
                        iconOnly
                        onClick={(event) => {
                          menu({
                            open: event.currentTarget,
                            align: "right",
                            options: [
                              {
                                name: "Descargar reporte",
                                icon: { icon: "download", color: getUproColor("uproGreen") },
                                onClick: () => {
                                  let reporte =
                                    reportes?.reporteCarreras?.carrerasMasInscriptas || [];

                                  let reporteExportar = reporte.map((item) => {
                                    return tipo === "Preinscripto"
                                      ? {
                                          Carrera: item.carrera.nombre,
                                          "Cantidad de preinscriptos": item.cantidad,
                                        }
                                      : {
                                          Carrera: item.carrera.nombre,
                                          "Cantidad de inscriptos": item.cantidad,
                                        };
                                  });
                                  const cabecera = [
                                    [
                                      `Cantidad de ${
                                        tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                      } por ${tipoCarrera.toLowerCase()} - ${dayjs().format(
                                        "DD-MM-YYYY"
                                      )}`,
                                    ],
                                  ];

                                  const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                                  XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                    skipHeader: false,
                                    origin: -1,
                                  });

                                  sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                                  const workbook = XLSX.utils.book_new();
                                  XLSX.utils.book_append_sheet(
                                    workbook,
                                    sheet,
                                    `Reporte ${dayjs().format("DD-MM-YYYY")}`
                                  );

                                  XLSX.writeFile(
                                    workbook,
                                    `Reporte ${
                                      tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                    } por ${tipoCarrera.toLowerCase()} - ${dayjs().format(
                                      "DD-MM-YYYY"
                                    )}.xlsx`
                                  );
                                },
                              },
                            ],
                            handleClose: () => {},
                          });
                        }}
                      >
                        <Icon>more_vert</Icon>
                      </SoftButton>
                    </SoftBox>
                    <SoftBox
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <SoftBox
                        component="ul"
                        display="flex"
                        flexDirection="column"
                        sx={{
                          listStyle: "none",
                          height: "fit-content",
                          transition: "all 150ms ease",
                        }}
                      >
                        {reportes?.reporteCarreras?.totalInscripcionesCarrreras
                          ?.slice(0, 6)
                          .map((item, index) => (
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              key={index}
                              mb={0.5}
                            >
                              <Tooltip
                                placement="top"
                                title={`${item.carrera.nombre} - ${item.cantidad} inscriptos`}
                              >
                                <SoftBox
                                  display="flex"
                                  justifyContent="start"
                                  alignItems="center"
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <SoftBox mr={2}>
                                    <SoftButton
                                      size="small"
                                      variant="gradient"
                                      color="dark"
                                      circular
                                      iconOnly
                                    >
                                      {index + 1}
                                    </SoftButton>
                                  </SoftBox>
                                  <SoftBox>
                                    <SoftTypography variant="button" fontWeight="medium">
                                      {item.carrera.nombre.length > 40
                                        ? `${item.carrera.nombre.slice(0, 40)}...`
                                        : item.carrera.nombre}
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </Tooltip>
                              <SoftBox>
                                <SoftTypography variant="button" fontWeight="medium">
                                  {item.cantidad}
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          ))}
                        <Slide direction="up" in={expandCarreras} mountOnEnter unmountOnExit>
                          <SoftBox>
                            {reportes?.reporteCarreras?.totalInscripcionesCarrreras
                              ?.slice(
                                6,
                                reportes?.reporteCarreras?.totalInscripcionesCarrreras?.length
                              )
                              .map((item, index) => (
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  key={index}
                                  mb={0.5}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={`${item.carrera.nombre} - ${item.cantidad} inscriptos`}
                                  >
                                    <SoftBox
                                      display="flex"
                                      justifyContent="start"
                                      alignItems="center"
                                      sx={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <SoftBox mr={2}>
                                        <SoftButton
                                          size="small"
                                          variant="gradient"
                                          color="dark"
                                          circular
                                          iconOnly
                                        >
                                          {index + 7}
                                        </SoftButton>
                                      </SoftBox>
                                      <SoftBox>
                                        <SoftTypography variant="button" fontWeight="medium">
                                          {item.carrera.nombre.length > 40
                                            ? `${item.carrera.nombre.slice(0, 40)}...`
                                            : item.carrera.nombre}
                                        </SoftTypography>
                                      </SoftBox>
                                    </SoftBox>
                                  </Tooltip>
                                  <SoftBox>
                                    <SoftTypography variant="button" fontWeight="medium">
                                      {item.cantidad}
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              ))}
                          </SoftBox>
                        </Slide>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox mt={2}>
                    <SoftButton
                      color="primary"
                      onClick={() => setExpandCarreras(!expandCarreras)}
                      circular
                      fullWidth
                    >
                      {expandCarreras ? "Ver menos" : "Ver más"}
                      <Icon
                        sx={{
                          rotate: expandCarreras ? "180deg" : "0deg",
                          transition: "all 0.5s",
                        }}
                      >
                        expand_more
                      </Icon>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          )}
          {tipoCarrera === "Carrera" && (
            <Grid item xs={12} sm={8}>
              <Card>
                <SoftBox p={3}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                    <SoftTypography variant="h6">
                      {`Cantidad de ${
                        tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                      } por unidad académica`}
                    </SoftTypography>
                    <SoftButton
                      size="small"
                      variant="gradient"
                      color="dark"
                      circular
                      iconOnly
                      onClick={(event) => {
                        menu({
                          open: event.currentTarget,
                          align: "right",
                          options: [
                            {
                              name: "Descargar reporte",
                              icon: { icon: "download", color: getUproColor("uproGreen") },
                              onClick: () => {
                                let reporte =
                                  reportes?.reporteCarreras?.inscripcionesPorUnidadAcademica || [];

                                let reporteExportar = reporte.map((item) => {
                                  return tipo === "Preinscripto"
                                    ? {
                                        "Unidad Académica": item.unidadAcademica.nombre,
                                        "Cantidad de preinscriptos": item.cantidad,
                                      }
                                    : {
                                        "Unidad Académica": item.unidadAcademica.nombre,
                                        "Cantidad de inscriptos": item.cantidad,
                                      };
                                });
                                const cabecera = [
                                  [
                                    `Cantidad de ${
                                      tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                    } por unidad académica - ${dayjs().format("DD-MM-YYYY")}`,
                                  ],
                                ];

                                const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                                XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                  skipHeader: false,
                                  origin: -1,
                                });

                                sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                                const workbook = XLSX.utils.book_new();
                                XLSX.utils.book_append_sheet(
                                  workbook,
                                  sheet,
                                  `Reporte ${dayjs().format("DD-MM-YYYY")}`
                                );

                                XLSX.writeFile(
                                  workbook,
                                  `Reporte ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por unidad académica - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                                );
                              },
                            },
                          ],
                          handleClose: () => {},
                        });
                      }}
                    >
                      <Icon>more_vert</Icon>
                    </SoftButton>
                  </SoftBox>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <SoftBox>
                        {reportes?.reporteCarreras?.inscripcionesPorUnidadAcademica?.map(
                          (item, index) => (
                            <SoftBox
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              key={index}
                            >
                              <SoftBox display="flex" alignItems="center">
                                <SoftBox mr={2}>
                                  <SoftButton
                                    size="small"
                                    variant="color"
                                    color={uproColors[index]}
                                    circular
                                    iconOnly
                                  />
                                </SoftBox>
                                <SoftTypography variant="button" fontWeight="medium">
                                  {`${item.unidadAcademica.nombre} (${item.cantidad})`}
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          )
                        )}
                        <SoftBox pt={2}>
                          <SoftButton variant="gradient" color="uproGreen" circular>
                            {`Total ${formatMoneyPunto(
                              reportes?.reporteCarreras?.totalInscripciones || 0
                            )}`}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DoughnutChart
                        id={4}
                        title="Inclusión Laboral"
                        chart={{
                          labels: reportes?.reporteCarreras?.inscripcionesPorUnidadAcademica?.map(
                            (item) => item.unidadAcademica.nombre
                          ),
                          datasets: {
                            backgroundColors:
                              reportes?.reporteCarreras?.inscripcionesPorUnidadAcademica?.map(
                                (item, index) => uproColors[index]
                              ),
                            data: reportes?.reporteCarreras?.inscripcionesPorUnidadAcademica?.map(
                              (item) => item.cantidad
                            ),
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={tipoCarrera === "Carrera" ? 12 : 8}
            sx={
              selectedSede
                ? {
                    paddingTop: "0 !important",
                    transition: "padding 0.5s",
                  }
                : {
                    transition: "padding 0.5s",
                  }
            }
          >
            <Collapse in={!selectedSede}>
              <Card>
                <SoftBox p={3}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                    <SoftTypography variant="h6">
                      {`Distribución de ${
                        tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                      } por sede`}
                    </SoftTypography>
                    <SoftButton
                      size="small"
                      variant="gradient"
                      color="dark"
                      circular
                      iconOnly
                      onClick={(event) => {
                        menu({
                          open: event.currentTarget,
                          align: "right",
                          options: [
                            {
                              name: "Descargar reporte",
                              icon: { icon: "download", color: getUproColor("uproGreen") },
                              onClick: () => {
                                let reporte = reportes?.reporteCarreras?.totalPorSede || [];
                                let reporteOrdenado = [...reporte].sort(
                                  (a, b) => b.cantidad - a.cantidad
                                );

                                let reporteExportar = reporteOrdenado.map((item) => {
                                  return tipo === "Preinscripto"
                                    ? {
                                        Sede: item.sede.nombre,
                                        "Cantidad de preinscriptos": item.cantidad,
                                      }
                                    : {
                                        Sede: item.sede.nombre,
                                        "Cantidad de inscriptos": item.cantidad,
                                      };
                                });
                                const cabecera = [
                                  [
                                    `Cantidad de ${
                                      tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                    } por sede - ${dayjs().format("DD-MM-YYYY")}`,
                                  ],
                                ];

                                const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                                XLSX.utils.sheet_add_json(sheet, reporteExportar, {
                                  skipHeader: false,
                                  origin: -1,
                                });

                                sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];

                                const workbook = XLSX.utils.book_new();
                                XLSX.utils.book_append_sheet(
                                  workbook,
                                  sheet,
                                  `Reporte ${dayjs().format("DD-MM-YYYY")}`
                                );

                                XLSX.writeFile(
                                  workbook,
                                  `Reporte ${
                                    tipo === "Preinscripto" ? "preinscriptos" : "inscriptos"
                                  } por sede - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                                );
                              },
                            },
                          ],
                          handleClose: () => {},
                        });
                      }}
                    >
                      <Icon>more_vert</Icon>
                    </SoftButton>
                  </SoftBox>
                  <VerticalBarChart
                    chart={{
                      labels:
                        reportes?.reporteCarreras?.totalPorSede?.map(
                          (item) => item?.sede?.nombre
                        ) || [],
                      datasets: [
                        {
                          label: "Cantidad de inscriptos",
                          color: "uproGreen",
                          data: reportes?.reporteCarreras?.totalPorSede?.map(
                            (item) => item.cantidad
                          ),
                        },
                      ],
                    }}
                  />
                </SoftBox>
              </Card>
            </Collapse>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

ReporteCarreras.defaultProps = {
  tipo: "Preinscripto",
  tipoCarrera: "Carrera",
  sedes: [],
};

ReporteCarreras.propTypes = {
  tipo: PropTypes.string,
  sedes: PropTypes.array.isRequired,
  tipoCarrera: PropTypes.string,
};

export default ReporteCarreras;
