/* eslint-disable react/prop-types */

import ModalStatus from "components/Modals/Status";
import { createContext, useState } from "react";

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({
    open: false,
    handleClose: () => {},
    type: "success",
  });

  const modal = ({ type, title, subtitle, message }) => {
    setModalData({
      type,
      open: true,
      handleClose: () => setModalData((prev) => ({ ...prev, open: false })),
      title,
      subtitle,
      message,
    });
  };

  return (
    <ModalContext.Provider value={{ modal }}>
      <ModalStatus {...modalData} />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
