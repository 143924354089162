// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalSeleccionAlumno({ open, handleClose, func, loading }) {
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [alumno, setAlumno] = useState();
  const [alumnos, setAlumnos] = useState([]);
  const [search, setSearch] = useState("");
  const [getAlumnos, { loadingAlumnos }] = useLazyQuery(gql`
    query getAlumnos($search: String) {
      usuarios(
        filter: { tipoUser: "Alumno" }
        search: $search
        pagination: { page: 1, limit: 50 }
      ) {
        id
        nombre
        apellido
      }
    }
  `);

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  useEffect(() => {
    if (open && search) {
      getAlumnos({
        variables: {
          search: search,
        },
      }).then((res) => {
        if (res.data.usuarios) {
          setAlumnos(res.data.usuarios);
        }
      });
    } else {
      setAlumno();
      setAlumnos([]);
      setSearch("");
    }
  }, [open, search]);

  useEffect(() => {
    if (alumnos.length > 0) {
      setAlumno(alumnos[0].id);
    } else {
      setAlumno();
    }
  }, [alumnos]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Agregar alumno a la comisión</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox pb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Alumno
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    placeholder="Escribe aquí..."
                    icon={{ component: "search", direction: "left" }}
                    onChange={handleSearch}
                  />
                  <SoftBox mt={1}>
                    <Select
                      fullWidth
                      value={alumno || -1}
                      onChange={(e) => setAlumno(e.target.value)}
                    >
                      {loadingAlumnos ? (
                        <MenuItem value={-1} disabled>
                          <Skeleton variant="text" width={"100%"} />
                        </MenuItem>
                      ) : alumnos.length === 0 ? (
                        <MenuItem value={-1} disabled>
                          No hay alumnos que coincidan con la busqueda
                        </MenuItem>
                      ) : (
                        alumnos.map((alumno) => (
                          <MenuItem key={alumno.id} value={alumno.id}>
                            {alumno.nombre} {alumno.apellido}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="uproGreen"
                  circular
                  disabled={loadingAlumnos || loading || alumnos.length === 0 || !alumno}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={() => {
                    func(alumno);
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  &nbsp;Agregar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSeleccionAlumno.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  loading: PropTypes.bool,
  func: PropTypes.func,
};
