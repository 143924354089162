// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, CircularProgress, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { MenuContext } from "context/menuContext";
import dataAsistencia from "layouts/autogestion-docente/data/dataAsistencia";
import { getUproColor } from "utils/colors";
import SoftButton from "components/SoftButton";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
};

export default function ModalTomarAsistencia({ open, handleClose, selectedComision, refetch }) {
  const [rows, setRows] = useState([]);
  const { columns, getRows } = dataAsistencia;
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState(null);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [registros, setRegistros] = useState([]);
  const [alumnos, setAlumnos] = useState([]);
  const { handleSnackbar } = useContext(MessageManager);
  const { menu } = useContext(MenuContext);

  const [orderBy, setOrderBy] = useState({
    campo: "nombreCompleto",
    orden: "ASC",
  });

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleMenu = (e, item, registro) => {
    menu({
      open: e.currentTarget,
      align: "right",
      options: [
        {
          name: "Ausente",
          disabled: registro.estado === "Ausente",
          icon: { icon: "clear", color: getUproColor("uproPink") },
          onClick: () => {
            handleSaveRegistro(item.id, "Ausente", registro.id);
          },
        },
        {
          name: "Justificado",
          disabled: registro.estado === "Justificado",
          icon: { icon: "edit_note", color: getUproColor("uproYellow") },
          onClick: () => {
            handleSaveRegistro(item.id, "Justificado", registro.id);
          },
        },
        {
          name: "Presente",
          disabled: registro.estado === "Presente",
          icon: { icon: "done", color: getUproColor("uproGreen") },
          onClick: () => {
            handleSaveRegistro(item.id, "Presente", registro.id);
          },
        },
      ],
    });
  };

  const [getComision, { data, loading, refetch: refetchComision }] = useLazyQuery(
    gql`
      query getComision($id: ID!, $search: String, $page: Int, $order: Order, $fecha: String) {
        paginationInfo {
          pages
          total
        }
        comision(id: $id) {
          id
          alumnos(search: $search, pagination: { page: $page, limit: 10 }, order: $order) {
            id
            nombre
            apellido
          }
          registrosAsistencia(fecha: $fecha) {
            id
            idAlumno
            estado
          }
        }
      }
    `,
    {
      cachePolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );

  const [saveRegistroAsistencia, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveRegistroAsistencia($input: [RegistroAsistenciaInput]!) {
        saveRegistroAsistencia(input: $input)
      }
    `
  );

  const [setAllStatusRegistroAsistencia, { loading: loadingAll }] = useMutation(
    gql`
      mutation setAllStatusRegistroAsistencia(
        $idComision: ID!
        $fecha: String!
        $estado: EstadoAsistencia!
      ) {
        setAllStatusRegistroAsistencia(idComision: $idComision, fecha: $fecha, estado: $estado)
      }
    `
  );

  const handleSaveRegistro = (idAlumno, estado, id) => {
    const index = registros.findIndex((item) => item.idAlumno === idAlumno);
    if (index === -1) {
      setRegistros([...registros, { id, idAlumno, estado }]);
    } else {
      const newArray = [...registros];
      newArray[index] = { id, idAlumno, estado };
      setRegistros(newArray);
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  useEffect(() => {
    if (open && selectedComision && !loadingAll) {
      getComision({
        variables: {
          id: selectedComision.comision.id,
          search,
          page,
          order: { field: orderBy.campo, order: orderBy.orden },
          fecha: selectedComision.fecha,
        },
      });
    } else {
      setAlumnos([]);
      setRegistros([]);
      setPage(1);
      setSearch("");
      refetch();
    }
  }, [open, selectedComision, search, page, orderBy, loadingAll]);

  useEffect(() => {
    if (data) {
      const { comision } = data;
      let registrosData = [
        ...registros,
        ...comision.registrosAsistencia.filter(
          (item) => !registros.find((r) => r.idAlumno === item.idAlumno)
        ),
      ];
      setRegistros(registrosData);
      setAlumnos(comision.alumnos);
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  useEffect(() => {
    setRows(getRows(alumnos, registros, handleSaveRegistro, handleMenu));
  }, [registros]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <SoftBox
                    display={{
                      xs: "flex-row",
                      sm: "flex",
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftBox display="flex" alignItems="center" gap={1}>
                        <SoftTypography variant="h6">
                          {`Asistencia comisión ${selectedComision?.comision?.codigo} - ${
                            selectedComision?.comision?.nombre
                          } del día ${dayjs(selectedComision?.fecha, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}`}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        alignItems="center"
                        sx={{
                          display: {
                            xs: "flex",
                            sm: "none",
                          },
                        }}
                      >
                        <Tooltip title="Cerrar" placement="top">
                          <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      alignItems="center"
                      justifyContent="end"
                      sx={{
                        display: {
                          xs: "none",
                          sm: "flex",
                        },
                      }}
                    >
                      <SoftBox
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        mr={2}
                        mt={{
                          xs: 2,
                          sm: 0,
                        }}
                      >
                        <SoftButton
                          circular
                          color="uproGreen"
                          disabled={loadingSave || registros.length === 0}
                          onClick={() => {
                            saveRegistroAsistencia({
                              variables: {
                                input: registros.map((item) => ({
                                  id: item.id || null,
                                  idComision: selectedComision.comision.id,
                                  fecha: selectedComision.fecha,
                                  idAlumno: item.idAlumno,
                                  estado: item.estado,
                                })),
                              },
                            })
                              .then(() => {
                                handleSnackbar("Asistencia guardada correctamente", "success");
                                refetchComision();
                              })
                              .catch((err) => {
                                handleSnackbar(
                                  err.message || "Error al guardar la asistencia",
                                  "error"
                                );
                              });
                          }}
                        >
                          {loadingSave ? (
                            <CircularProgress size={15} color="inherit" />
                          ) : (
                            <Icon>save</Icon>
                          )}
                          &nbsp; Guardar
                        </SoftButton>
                      </SoftBox>
                      <SoftBox
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        mr={2}
                        mt={{
                          xs: 2,
                          sm: 0,
                        }}
                      >
                        <SoftButton
                          circular
                          iconOnly
                          color="uproYellow"
                          onClick={(e) => {
                            menu({
                              open: e.currentTarget,
                              align: "right",
                              options: [
                                {
                                  name: "Todos ausentes",
                                  icon: { icon: "clear", color: getUproColor("uproPink") },
                                  onClick: () => {
                                    setAllStatusRegistroAsistencia({
                                      variables: {
                                        idComision: selectedComision.comision.id,
                                        fecha: selectedComision.fecha,
                                        estado: "Ausente",
                                      },
                                    })
                                      .then(() => {
                                        handleSnackbar(
                                          "Asistencia guardada correctamente",
                                          "success"
                                        );
                                      })
                                      .catch((err) => {
                                        handleSnackbar(
                                          err.message || "Error al guardar la asistencia",
                                          "error"
                                        );
                                      });
                                  },
                                },
                                {
                                  name: "Todos justificados",
                                  icon: { icon: "edit_note", color: getUproColor("uproYellow") },
                                  onClick: () => {
                                    setAllStatusRegistroAsistencia({
                                      variables: {
                                        idComision: selectedComision.comision.id,
                                        fecha: selectedComision.fecha,
                                        estado: "Justificado",
                                      },
                                    })
                                      .then(() => {
                                        handleSnackbar(
                                          "Asistencia guardada correctamente",
                                          "success"
                                        );
                                      })
                                      .catch((err) => {
                                        handleSnackbar(
                                          err.message || "Error al guardar la asistencia",
                                          "error"
                                        );
                                      });
                                  },
                                },
                                {
                                  name: "Todos presentes",
                                  icon: { icon: "done", color: getUproColor("uproGreen") },
                                  onClick: () => {
                                    setAllStatusRegistroAsistencia({
                                      variables: {
                                        idComision: selectedComision.comision.id,
                                        fecha: selectedComision.fecha,
                                        estado: "Presente",
                                      },
                                    })
                                      .then(() => {
                                        handleSnackbar(
                                          "Asistencia guardada correctamente",
                                          "success"
                                        );
                                      })
                                      .catch((err) => {
                                        handleSnackbar(
                                          err.message || "Error al guardar la asistencia",
                                          "error"
                                        );
                                      });
                                  },
                                },
                              ],
                            });
                          }}
                        >
                          <Icon>more_vert</Icon>
                        </SoftButton>
                      </SoftBox>
                      <SoftBox display="flex" alignItems="center">
                        <Tooltip title="Cerrar" placement="top">
                          <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                            close
                          </Icon>
                        </Tooltip>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      sx={{
                        display: {
                          xs: "flex",
                          sm: "none",
                        },
                      }}
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftButton
                        circular
                        color="uproGreen"
                        fullWidth
                        disabled={loadingSave || registros.length === 0}
                        onClick={() => {
                          saveRegistroAsistencia({
                            variables: {
                              input: registros.map((item) => ({
                                id: item.id || null,
                                idComision: selectedComision.comision.id,
                                fecha: selectedComision.fecha,
                                idAlumno: item.idAlumno,
                                estado: item.estado,
                              })),
                            },
                          })
                            .then(() => {
                              handleSnackbar("Asistencia guardada correctamente", "success");
                            })
                            .catch((err) => {
                              handleSnackbar(
                                err.message || "Error al guardar la asistencia",
                                "error"
                              );
                            });
                        }}
                      >
                        {loadingSave ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          <Icon>save</Icon>
                        )}
                        &nbsp; Guardar
                      </SoftButton>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                      sx={{
                        display: {
                          xs: "flex",
                          sm: "none",
                        },
                      }}
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                    >
                      <SoftButton
                        circular
                        fullWidth
                        color="uproYellow"
                        onClick={(e) => {
                          menu({
                            open: e.currentTarget,
                            align: "right",
                            options: [
                              {
                                name: "Todos ausentes",
                                icon: { icon: "clear", color: getUproColor("uproPink") },
                                onClick: () => {
                                  setAllStatusRegistroAsistencia({
                                    variables: {
                                      idComision: selectedComision.comision.id,
                                      fecha: selectedComision.fecha,
                                      estado: "Ausente",
                                    },
                                  })
                                    .then(() => {
                                      handleSnackbar(
                                        "Asistencia guardada correctamente",
                                        "success"
                                      );
                                    })
                                    .catch((err) => {
                                      handleSnackbar(
                                        err.message || "Error al guardar la asistencia",
                                        "error"
                                      );
                                    });
                                },
                              },
                              {
                                name: "Todos justificados",
                                icon: { icon: "edit_note", color: getUproColor("uproYellow") },
                                onClick: () => {
                                  setAllStatusRegistroAsistencia({
                                    variables: {
                                      idComision: selectedComision.comision.id,
                                      fecha: selectedComision.fecha,
                                      estado: "Justificado",
                                    },
                                  })
                                    .then(() => {
                                      handleSnackbar(
                                        "Asistencia guardada correctamente",
                                        "success"
                                      );
                                    })
                                    .catch((err) => {
                                      handleSnackbar(
                                        err.message || "Error al guardar la asistencia",
                                        "error"
                                      );
                                    });
                                },
                              },
                              {
                                name: "Todos presentes",
                                icon: { icon: "done", color: getUproColor("uproGreen") },
                                onClick: () => {
                                  setAllStatusRegistroAsistencia({
                                    variables: {
                                      idComision: selectedComision.comision.id,
                                      fecha: selectedComision.fecha,
                                      estado: "Presente",
                                    },
                                  })
                                    .then(() => {
                                      handleSnackbar(
                                        "Asistencia guardada correctamente",
                                        "success"
                                      );
                                    })
                                    .catch((err) => {
                                      handleSnackbar(
                                        err.message || "Error al guardar la asistencia",
                                        "error"
                                      );
                                    });
                                },
                              },
                            ],
                          });
                        }}
                      >
                        <Icon>more_vert</Icon>
                        &nbsp; Más opciones
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftInput
                      placeholder="Escribe aquí..."
                      icon={{ component: "search", direction: "left" }}
                      onChange={handleSearch}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                    order={orderBy}
                    setOrder={handleOrderBy}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={rows?.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </Card>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalTomarAsistencia.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedComision: PropTypes.object,
  refetch: PropTypes.func,
};
