/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";

const getRows = (data) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      let sedes = [];
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color:
                    item?.tipoUser === "Alumno"
                      ? getUproColor("uproYellow")
                      : item?.tipoUser === "Docente"
                      ? getUproColor("uproGreen")
                      : getUproColor("uproPink"),
                }}
                fontSize="lg"
              >
                {item?.tipoUser === "Alumno"
                  ? "school"
                  : item?.tipoUser === "Docente"
                  ? "supervisor_account"
                  : "manage_accounts"}
              </Icon>
            }
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item?.tipoUser} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color:
                      item?.tipoUser === "Alumno"
                        ? getUproColor("uproYellow")
                        : item?.tipoUser === "Docente"
                        ? getUproColor("uproGreen")
                        : getUproColor("uproPink"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipoUser === "Alumno"
                    ? "school"
                    : item?.tipoUser === "Docente"
                    ? "supervisor_account"
                    : "manage_accounts"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.apellido
                ? makeNiceText(item?.apellido + " " + item?.nombre, "nombre")
                : item?.username
            }
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(item?.apellido + " " + item?.nombre, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        dni: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={formatMoneyPunto(item?.dni)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {formatMoneyPunto(item?.dni)}
            </SoftTypography>
          </SoftBox>
        ),
        comision: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.inscripcionesComisiones?.length > 0
                ? `${item?.inscripcionesComisiones[0]?.comision?.codigo} - ${item?.inscripcionesComisiones[0]?.comision?.nombre}`
                : "Sin comisión"
            }
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.inscripcionesComisiones?.length > 0
                ? `${item?.inscripcionesComisiones[0]?.comision?.codigo} - ${item?.inscripcionesComisiones[0]?.comision?.nombre}`
                : "Sin comisión"}
            </SoftTypography>
          </SoftBox>
        ),
        asistencias: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.asistencias?.ausentes}
          >
            <SoftBox display="flex" alignItems="center" justifyContent="center" gap={1}>
              <Tooltip title="Ausentes" placement="top">
                <SoftButton color="primary" circular iconOnly>
                  {item?.asistencias?.ausentes}
                </SoftButton>
              </Tooltip>
              <Tooltip title="Justificados" placement="top">
                <SoftButton color="uproYellow" circular iconOnly>
                  {item?.asistencias?.justificados}
                </SoftButton>
              </Tooltip>
              <Tooltip title="Presentes" placement="top">
                <SoftButton color="uproGreen" circular iconOnly>
                  {item?.asistencias?.presentes}
                </SoftButton>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left", orderField: "nombreCompleto" },
  { name: "dni", desc: "DNI", align: "left" },
  { name: "comision", desc: "Comision", align: "left", orderField: "comision", noOrder: true },
  {
    name: "asistencias",
    desc: "Asistencias",
    align: "center",
    noOrder: true,
  },
];

export default { columns, getRows };
