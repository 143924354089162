import { Backdrop, Card, Fade, Icon, Modal } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "30%" },
};

export default function ModalStatus({ open, handleClose, type, title, subtitle, message }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox
            bgColor={type === "error" ? "primary" : getUproColor("uproGreen")}
            p={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Icon
              fontSize="large"
              sx={{
                color: "#fff",
                animation: "shakes 1s",
                "@keyframes shakes": {
                  "0%": {
                    transform: "rotate(0deg)",
                  },
                  "25%": {
                    transform: "rotate(10deg)",
                  },
                  "50%": {
                    transform: "rotate(-20deg)",
                  },
                  "75%": {
                    transform: "rotate(15deg)",
                  },
                  "100%": {
                    transform: "rotate(-5deg)",
                  },
                },
              }}
            >
              {type === "error" ? "error" : "check_circle"}
            </Icon>
            <SoftTypography variant="h5" color="white" fontWeight="bold" mt={2}>
              {title || (type === "error" ? "Error" : "Éxito")}
            </SoftTypography>
            {subtitle && (
              <SoftTypography variant="h6" color="white" fontWeight="bold" mt={1}>
                {subtitle}
              </SoftTypography>
            )}
            {message && (
              <SoftTypography variant="caption" color="white" mt={1}>
                {message}
              </SoftTypography>
            )}
            <SoftBox mt={2}>
              <SoftButton color="white" circular onClick={handleClose}>
                <SoftTypography variant="button" color="uproGreen">
                  CERRAR
                </SoftTypography>
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalStatus.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  message: PropTypes.string,
};
