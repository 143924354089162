/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data, registrosAsistencia, handleSaveRegistro, handleMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      const estado = registrosAsistencia?.find((registro) => registro.idAlumno === item?.id);
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{}}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("uproBlue"),
                }}
                fontSize="lg"
              >
                person
              </Icon>
            }
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Alumno"} placement="top">
                <Icon
                  color="primary"
                  sx={{
                    verticalAlign: "middle",
                  }}
                  fontSize="lg"
                >
                  person
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        nombre: (
          <SoftBox sx={{}} data={makeNiceText(`${item?.apellido} ${item?.nombre}`, "nombre")}>
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {makeNiceText(`${item?.apellido} ${item?.nombre}`, "nombre")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: estado ? (
          <SoftBox mr={2} display="flex" alignItems="center" justifyContent="flex-end">
            <Tooltip title={"Click para cambiar"} placement="top">
              <SoftBox>
                <SoftButton
                  color={
                    estado?.estado === "Presente"
                      ? "uproGreen"
                      : estado?.estado === "Ausente"
                      ? "primary"
                      : "uproYellow"
                  }
                  circular
                  onClick={(e) => handleMenu(e, item, estado)}
                >
                  {estado?.estado}&nbsp;
                  <Icon>
                    {estado?.estado === "Presente"
                      ? "done"
                      : estado?.estado === "Ausente"
                      ? "clear"
                      : "edit_note"}
                  </Icon>
                </SoftButton>
              </SoftBox>
            </Tooltip>
          </SoftBox>
        ) : (
          <SoftBox mr={2} display="flex" alignItems="center" justifyContent="flex-end">
            <Tooltip title={"Ausente"} placement="top">
              <SoftBox mr={1}>
                <SoftButton
                  color="primary"
                  circular
                  iconOnly
                  onClick={() => handleSaveRegistro(item?.id, "Ausente")}
                >
                  <Icon>clear</Icon>
                </SoftButton>
              </SoftBox>
            </Tooltip>
            <Tooltip title={"Justificado"} placement="top">
              <SoftBox mr={1}>
                <SoftButton
                  color="uproYellow"
                  circular
                  iconOnly
                  onClick={() => handleSaveRegistro(item?.id, "Justificado")}
                >
                  <Icon>edit_note</Icon>
                </SoftButton>
              </SoftBox>
            </Tooltip>
            <Tooltip title={"Presente"} placement="top">
              <SoftBox>
                <SoftButton
                  color="uproGreen"
                  circular
                  iconOnly
                  onClick={() => handleSaveRegistro(item?.id, "Presente")}
                >
                  <Icon>done</Icon>
                </SoftButton>
              </SoftBox>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
