// React
import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import ModalDelete from "components/Modals/Delete";
import {
  Card,
  Fade,
  Grid,
  Icon,
  Pagination,
  Skeleton,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";

// Data
import dataUsuarios from "layouts/usuarios/data/dataUsuarios";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import { getUproColor } from "utils/colors";
import SoftInput from "components/SoftInput";
import ModalUsuarios from "./ModalUsuario";
import { MessageManager } from "context";
import { UserContext } from "context/user";

function Usuarios() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombreCompleto",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataUsuarios;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedUsuario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteUser] = useMutation(
    gql`
      mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
          id
        }
      }
    `
  );

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getUsuarios($search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        usuarios(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: { tipoUser: "Gestión Académica" }
        ) {
          id
          nombre
          tipoUser
          idsPermisos
          apellido
          username
          tempPassword
          email
          tipoUser
          nroLegajo
          dni
          fechaNacimiento
          pais{
            id
          }
          genero
          esDiscapacitado
          discapacidad
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
      cachePolicy: "no-cache",
    }
  );

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      <MenuItem
        disabled={!verificarAcceso(2)}
        onClick={() => {
          setOpenModalAdd(true);
          handleCloseMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Editar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("uproGreen"),
              }}
            >
              edit
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
          disabled={!verificarAcceso(3) || selectedUsuario?.tipoUser === "Administrador"}
          onClick={() => {
            setOpenModalDelete(true);
            handleCloseMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox>
              <SoftTypography variant="p">Eliminar</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    selectedUsuario?.tipoUser === "Administrador"
                      ? getUproColor()
                      : getUproColor("uproPink"),
                }}
              >
                delete
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
    </Menu>
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser({ variables: { id } });
      refetch();
      handleSnackbar("Usuario eliminado correctamente", "success");
    } catch (error) {
      handleSnackbar("Error al eliminar usuario", "error");
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.usuarios, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Usuarios</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Agregar nuevo usuario" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          iconOnly
                          onClick={() => setOpenModalAdd(true)}
                          disabled={!verificarAcceso(1)}
                        >
                          <Icon>add</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Agregar nuevo usuario" placement="top">
                        <SoftButton color="primary" circular disabled={!verificarAcceso(1)} onClick={() => setOpenModalAdd(true)}>
                          <Icon>add</Icon>
                          &nbsp;Agregar
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : rows?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.usuarios.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
          {renderMenu}
          <ModalUsuarios
            open={openModalAdd}
            handleClose={() => {
              setOpenModalAdd(false);
              setSelectedUsuario();
            }}
            refetch={refetch}
            selectedUsuario={selectedUsuario}
            tipo="Gestión Académica"
          />
          <ModalDelete
            open={openModalDelete}
            handleClose={() => setOpenModalDelete(false)}
            nombre={`el usuario ${selectedUsuario?.nombre} ${selectedUsuario?.apellido}`}
            functionDelete={() => {
              handleDeleteUser(selectedUsuario.id);
              setOpenModalDelete(false);
            }}
          />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Usuarios;
